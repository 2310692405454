<template>
  <div class="main-wrapper wrapper">
    <div class="swiper main-gallery" v-if="home?.settings?.image && home.settings.image.length">
      <HomeSlides :slides="home.settings.image" />
    </div>
    <!--    <div class="home-header custom-header" v-if="home?.settings">-->
    <!--      <HomeSlides :slides="home.settings.image"/>-->
    <!--      <div class="header-content-alt bg-cover" v-if="home.settings.image" :style="headBackground">-->
    <!--        <router-link :to="{ name: 'catalog', params: { category: home.settings.url } }"-->
    <!--                     style="display: block"></router-link>-->
    <!--      </div>-->
    <!--      <div class="header-content" v-else-if="home.settings.header || home.settings.subtitle">-->
    <!--        <h1 v-html="home.settings.header"></h1>-->
    <!--        <div class="subtitle" v-html="home.settings.subtitle"></div>-->
    <!--        <div class="button-holder" v-if="home.settings.button && home.settings.url">-->
    <!--          <router-link :to="{ name: 'catalog', params: { category: home.settings.url } }" class="default-btn ">-->
    <!--            <span class="btn-text" style="margin-right: 0">{{ $static('home.to_catalog', 'До каталогу') }}</span>-->
    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="sorting-panel" v-if="tags && tags.length">
      <h2>{{ $static('home.for_all', 'Товари для кожного') }}</h2>
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{ $static('options.all', 'Усі') }}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
          <input :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label :for="op.id">
            <!--            <span class="pick" style="background-image: url('')" ></span>-->
            <span class="pick" v-if="op.image" :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">{{ $dynamic(op, op.name, 'name') }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="mainstream-products" v-if="items">
      <ul class="catalog-grid" ref="homeEle" @mousedown="(e) => mouseDownHandler(e, 'homeEle')">
        <CatalogItemPreview v-for="(item, itemIndex) in items" :key="item + itemIndex" :item="item"
          :categoryObject="categoriesArray[itemIndex]" @leaveLead="handleLeaveLeadBtnClick" />

        <li class="catalog-item more-catalog-item" @click="getMore" v-if="more">
          <div class="item-holder d-flex align-items-center text-center justify-content-center h-100">
            <div class="more-content">
              <div class="text-wrapper">
                {{ $static('catalog.more_load', 'Завантажити ще') }}
              </div>
              <div class="icons-holder">
                <img src="/images/icons/load.svg">
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!--      <div class="link-holder " v-if="home && home.settings && home.settings.url">-->
      <!--        <router-link :to="{ name: 'catalog', params: { category: home.settings.url } }"-->
      <!--                     class="default-btn btn-outline large-btn">-->
      <!--          <span class="text">{{ $static('home.back_catalog', 'Перейти до каталогу') }}</span>-->
      <!--          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--            <path-->
      <!--                d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"-->
      <!--                fill="black"/>-->
      <!--          </svg>-->
      <!--        </router-link>-->
      <!--      </div>-->
    </div>
    <div class="custom-home-text" v-if="home && home.template" v-html="home.template"></div>
  </div>
  <ServiceRequestModal :settings="settings" :item="selectedItem" v-if="selectedItem" />
</template>

<script>

import HomeSlides from '@/components/HomeSlides';
import CatalogItemPreview from '@/views/CatalogItemPreview';
import ServiceRequestModal from '@/components/ServiceRequestModal';
import { fixBodyPadding } from '@/utils/fixBodyPadding'

export default {
  name: 'Home',
  components: { CatalogItemPreview, ServiceRequestModal, HomeSlides },
  data() {
    return {
      items: [],
      selectedItem: undefined,
      page: 1,
      perPage: 11,
      more: true,

      optionSelect: [],
      swiperReview: undefined,
      swiperMain: undefined,

      order: {
        count: 1,
        selectedMod: 0,
        additionSelect: [],
        additionUSelect: []
      },

      homeEleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для ele
      },
    }
  },
  computed: {
    basked() {
      return this.$store.state.busked
    },
    settings() {
      return this.$store.getters.getSettings
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    categoriesArray() {
      if (this.items.length > 0 && this.categories.length > 0) {
        return this.items.map((item) => {
          let result = this.categories.find(i => i.id == item.category);
          if (!result) {
            return { info: { url: 'default' } }
          }
          return { info: { url: result.info.url } }
        }
        )
      }
      return []
    },
    // landing() {
    //   return this.$store.getters.landing
    // },
    lang() {
      return this.$lang()
    },
    // headBackground() {
    //   return `background-image: url(${this.home.settings.image});`
    // },
    logoSrc() {
      return this.settings && this.settings.logo ? this.settings.logo : '/images/logo.svg'
    },
    title() {
      return this.settings && this.settings.title ? this.settings.title : undefined
    },
    subtitle() {
      return this.settings && this.settings.subtitle ? this.settings.subtitle : undefined
    },

    tags() {
      return this.$store.getters.getTags.filter(i => i.info?.hidden)
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    home: {
      get() {
        return this.$store.getters.getPages ? this.$store.getters.getPages['home'] : {}
      },
      set(value) { }
    },
    isCatalogMod() {
      return this.$store.getters.getSettings?.catalog_mod
    },
    options_list() {
      return this.$store.getters.getOptions
    },
  },
  async mounted() {
    await this.getItems(true);
  },
  methods: {
    // isOptions(buyItem) {
    //   return buyItem?.advanced?.uOption?.length > 0 || (buyItem?.advanced?.options ? Object.keys(buyItem?.advanced?.options).length : false)
    // },
    hasOptions(item) {
      // Проверка наличия необходимых объектов
      if (
        item &&
        item.advanced &&
        item.advanced.options &&
        this.options_list
      ) {
        // Получаем массив ключей из this.item.advanced.options
        const itemOptionsKeys = Object.keys(item.advanced.options);

        // Используем метод some для проверки условий
        const res = itemOptionsKeys.some(key => {
          // Исключаем ключ "ingredient"
          if (key === "ingredient") return false;

          // Проверяем, существует ли такой же ключ в this.options_list
          if (!Object.prototype.hasOwnProperty.call(this.options_list, key)) return false;


          // Получаем массивы из обоих объектов
          const itemOptionIds = item.advanced.options[key];
          const optionList = this.options_list[key].list;
          // Проверяем, что оба массива непустые
          if (!Array.isArray(itemOptionIds) || itemOptionIds.length === 0) return false;
          if (!Array.isArray(optionList) || optionList.length === 0) return false;

          // Извлекаем массив ID из optionList
          const optionListIds = optionList.map(item => item.id);

          // Проверяем наличие хотя бы одного совпадения ID
          const hasMatch = itemOptionIds.some(id => optionListIds.includes(id));

          return hasMatch;
        });

        return res;
      }
      return false
    },

    buy(buyItem) {
      let idx = -1;
      idx = this.basked.findIndex(i => i.id === buyItem.id);
      if (idx > -1) {
        // this.basked[idx].count += this.order.count;
        this.$store.commit(
          'replaceBuskedIndex',
          { index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, buyItem))) }
        );
      } else {
        let item = JSON.parse(JSON.stringify(Object.assign({}, this.order, buyItem)))
        this.$store.commit(
          'addToBasked',
          item
        );
        this.$AddToCart(item)
      }
      this.toggleBusked()

      // fixBodyPadding();
      // window.$('body').addClass('no-scroll');
      // window.$('#quick_cart').addClass('opened');
      // window.$('.frame-modal-wrapper-bg').addClass('is-visible');
    },

    toggleBusked() {
      fixBodyPadding();

      this.$store.commit('setBuskedOpen', !this.isBuskedOpen)

      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.overlay-menu').removeClass('active');
      window.$('.open-nav').removeClass('is-visible');
      window.$('.show-menu-btn').removeClass('open');

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');


      window.$('.cart-modal-wrapper-bg').toggleClass('is-visible');
      window.$('.quick-card-sticker-li').toggleClass('active');
      window.$('#quick_cart').toggleClass('opened');

      window.$('body').toggleClass('no-scroll');
    },

    async getMore() {
      this.page += 1;
      await this.getItems()
    },

    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },
    mouseDownHandler(e, ref) {
      // Определяем элемент для нужного списка
      const scrollElement = ref === 'homeEle' ? this.$refs.homeEle : undefined;
      const data = ref === 'homeEle' ? this.homeEleData : undefined;

      data.isMouseDown = true; // Устанавливаем флаг для активного списка
      data.sourceTargetClick = e.target;
      data.sourceTargetClickTime = new Date().getTime();
      data.pos = {
        left: scrollElement.scrollLeft,
        top: scrollElement.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      data.initialX = e.clientX;

      if (!this.isTouchDevice()) {
        scrollElement.style.userSelect = 'none';

        // Привязываем контекст для функций-обработчиков
        document.addEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
        document.addEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
      }
    },
    mouseMoveHandler(ref, e) {
      const data = ref === 'homeEle' ? this.homeEleData : undefined;

      if (!data.isMouseDown) return; // Проверяем состояние для конкретного списка

      const scrollElement = ref === 'homeEle' ? this.$refs.homeEle : undefined;

      const dx = e.clientX - data.pos.x;
      const dy = e.clientY - data.pos.y;

      scrollElement.scrollTop = data.pos.top - dy;
      scrollElement.scrollLeft = data.pos.left - dx;
    },
    mouseUpHandler(ref, e) {
      const data = ref === 'homeEle' ? this.homeEleData : undefined;
      if (!data.isMouseDown) return;

      data.isMouseDown = false; // Сбрасываем флаг для конкретного списка

      const currentX = e.clientX;
      const xDifference = Math.abs(currentX - data.initialX);
      const now = new Date().getTime();

      if ((now - data.sourceTargetClickTime) < 500 && xDifference < 1) {
        let target = data.sourceTargetClick;
        while (target) {
          if (target.href) {
            this.$router.push(target.href.replaceAll(`${window.location.protocol}//${window.location.host}`, ""));
            break;
          }
          target = target.parentElement;
        }
      } else {
        document.addEventListener('click', this.preventClick, true);
      }
      document.removeEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
      document.removeEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
    },
    preventClick(e) {
      e.stopImmediatePropagation();  // Останавливаем любое событие клика
      e.preventDefault();  // Отменяем стандартное действие
      document.removeEventListener('click', this.preventClick, true);  // Удаляем этот обработчик после срабатывания
    },

    getColor(item) {
      return `background-color: ${item.color};`
    },

    getDiscount(item) {
      return item.preview ? (1 - Math.max(item.price / item.price_old, 0)) * 100 | 0 : ''
    },

    isHot(item) {
      return item.advanced && item.advanced.hot
    },

    async getItems(clear) {
      if (this.load) {
        return;
      }

      this.load = true;
      const filter = { priority: true, perPage: this.perPage, page: this.page };

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => (x ? x.id : x));
      }

      try {
        const response = await fetch('/public/item/filter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter: filter, sortBy: 'sort' })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.load = false;

        if (clear) {
          this.items = data.result;
        } else {
          this.items.push(...data.result);
        }

        this.page = data.page;
        this.total = data.total;

        if (this.items.length === this.total) {
          this.more = false;
        }
      } catch (error) {
        console.error('Error fetching items:', error);
        this.load = false;
      }
    },

    handleLeaveLeadBtnClick(item) {
      this.selectedItem = item;
    }
  },

  watch: {
    async optionSelect() {
      await this.getItems(true)
    },
    home(value) {
      if (value && value.settings) {
        let title = value.settings.meta_title ? value.settings.meta_title : window.location.hostname;

        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = value.settings.meta_description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');

        let image = value.settings.image ? value.settings.image : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  }
}
</script>

<style scoped>
.catalog-item .not-available .options,
.catalog-item .not-available .product-name {
  opacity: 0.6;
}

.catalog-item .not-available .image {
  filter: grayscale(100%);
}
</style>