<template>
  <a href="javascript:void(0);" class="search-btn" @click="openSearch">
    <img src="/images/icons/search.svg">
  </a>

  <Teleport to="body">
    <div class="frame-modal-wrapper-bg search-modal-wrapper-bg" :class="{ 'is-visible': searchOpen }"
      @click="closeSearch">
    </div>
    <div class="frame-modal-wrapper search-modal-wrapper" :class="{ 'opened': searchOpen }"
      :style="{ pointerEvents: 'none' }">
      <!--эта хрень прозрачна и чтоб пропускала событие клика на search-modal-wrapper-bg добавлен pointer-events: none-->
      <div class="modal-wrapper-holder" @click.stop.prevent="" :style="{ pointerEvents: 'auto' }">
        <div class=" frame-modal-header">
          <div class="name h3 no-uppercase">{{ $static('search.title', 'Пошук продукту') }}</div>
          <a href="javascript:void(0);" class="close-cart" @click="closeSearch">
            <img src="/images/icons/close.svg">
          </a>
        </div>
        <div class="frame-modal-body">
          <div class="search-input">
            <img src="/images/icons/search.svg">
            <input type="text" id="query" class="form-control custom-input" ref="query" v-model="search"
              placeholder="...">
          </div>
          <div class="search-list">
            <ul>
              <li class="cart-item search-item" v-for="(item, index) in items" :key="index">
                <a href="javascript:void(0)" @click="openItem(item)">
                  <div class="item-holder">
                    <div class="item-info">
                      <div class="image bg" :style="{ 'background-image': $filters.getItemImage(item.preview) }"></div>
                      <div class="right-side">
                        <div class="name">{{ $dynamic(item, item.title, 'title') }}</div>
                        <div class="option-box">
                          <div class="price-box">
                            <div class="price-element" v-if="item.price" :class="{ 'was-selled': !!item.price_old }">{{
                                $filters.money(item.price)
                              }} {{ $store.getters.getSettings.symbol }}</div>
<!--                            <div class="price-element">{{ $filters.money(item.price) }} {{-->
<!--                                $store.getters.getSettings.symbol }}</div>-->
                            <div class="price-element price-old" v-if="item.price_old">
                              {{ $filters.money(item.price_old) }} {{ $store.getters.getSettings.symbol }}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <div v-if="loading">{{ $static('search.loader-load_1', 'Йде пошук') }}</div>
              <div v-if="noResult">{{ $static('search.loader-noresult', 'Нічого не знайдено') }}</div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { fixBodyPadding } from '@/utils/fixBodyPadding';

export default {
  name: 'SearchItem',
  data() {
    return {
      search: '',
      loading: false,
      noResult: false,
      timer: undefined,
      perPage: 20,
      items: []
    }
  },

  computed: {
    searchOpen() {
      return this.$store.getters.getSearchOpen || false
    },
  },

  methods: {
    openSearch() {
      fixBodyPadding();
      this.$store.commit('setSearchOpen', true);
      window.$('body').addClass('no-scroll');
    },

    closeSearch() {
      fixBodyPadding();
      this.$store.commit('setSearchOpen', false);
      window.$('body').removeClass('no-scroll');
    },

    openItem(item) {
      fixBodyPadding();
      this.$store.commit('setSearchOpen', false)
      window.$('body').removeClass('no-scroll');
      this.$router.push({ name: 'ItemOrServiceItem', params: { category: this.categoryURL(item), id: item.id }, query: this.$route.query })
    },

    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },

    categoryURL(item) {
      // return {id: 1}
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },

    async getQuery() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      let filter = { perPage: this.perPage, page: this.page };
      if (this.search) {
        filter.search = this.search;
      }

      try {
        const response = await fetch('/public/item/filter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter })
        });

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const data = await response.json();

        this.items = data.result;
        this.page = data.page;
        this.total = data.total;

        if (this.items.length === this.total) {
          this.more = false;
        }

        this.noResult = !(Array.isArray(data.result) && data.result.length);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        this.loading = false;
      }
    }
  },

  watch: {
    search(val) {
      this.noResult = false
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = undefined;
      }
      this.timer = setTimeout(async () => {
        await this.getQuery()
      }, 800);
    },

    async searchOpen(val) {
      if (val) {
        if (this.items.length === 0) {
          await this.getQuery()
        }
        setTimeout(() => {
          this.$refs.query.focus()
        }, 100)

        fixBodyPadding();

        this.$store.commit('setBuskedOpen', false)

        this.$store.commit('setReadMoreOpen', false)

        window.$('.overlay-menu').removeClass('active');
        window.$('.open-nav').removeClass('is-visible');
        window.$('.show-menu-btn').removeClass('open');

        window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
        window.$('.personal_area-sidebar').removeClass('opened');
        window.$('.login-trigger-item').removeClass('active');


        window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
        window.$('.quick-card-sticker-li').removeClass('active');
        window.$('#quick_cart').removeClass('opened');

        this.$store.commit('setSearchOpen', true);
      } else {
        this.$store.commit('setSearchOpen', false);
      }
    }
  }
}
</script>

<style scoped>
/*@media (orientation: landscape) {*/
/*  .frame-modal-wrapper.search-modal-wrapper .search-list {*/
/*    height: auto;*/
/*  }*/
/*}*/
</style>