<template>
  <main :class="{ 'bg-contain': isContain }">
    <div class="overlay-menu">
      <div class="overlay-top">
        <router-link :to="{ name: 'home' }">
          <div class="logo">
            <img :src="logo">
          </div>
        </router-link>
        <a class="close-menu-btn" href="javascript:void(0);" @click.prevent="handleCloseMenuBtnClick">
          <img src="/images/icons/close.svg">
        </a>
      </div>

      <div class="overlay-menu-wrapper">
        <div class="menu-list"> <!--if menu has sublevels then add class .with-sublevel-->
          <div class="back-level">
            <a class="back_level_trigger" href="javascript:void(0);">
              <img src="/images/icons/left.svg">
              <span class="text">Назад</span>
            </a>
          </div>
          <ul>
            <li class="dflt-link">
              <router-link :to="{ name: 'home' }" :exact-active-class="'active'">
                <span class="text">{{ $static('menu.home', 'Головна') }}</span>
              </router-link>
            </li>

            <!--            <li class="categories-menu" v-if="menu_type==='advanced' && menu">-->
            <!--              <span class="title-categories-menu">{{ $static('menu.ct', 'Категорії') }}</span>-->
            <!--              <ul class="categories-list">-->
            <!--                <li v-for="(category,index) in top" :key="'category-'+index">-->
            <!--                  <router-link :to="'/catalog/'+categoryURL(category)+`?hash=${category.hash}`">-->
            <!--                    <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>-->
            <!--                  </router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->

            <!--            <li class="categories-menu" >-->
            <!--              <span class="title-categories-menu">{{ $static('menu.ct', 'Категорії') }}</span>-->
            <!--              <ul class="categories-list">-->
            <!--                <li v-for="(category, index) in categories" :key="index">-->
            <!--                  <router-link :to="'/catalog/'+category.info.url" :activeClass="'active'">-->
            <!--                    <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>-->
            <!--                  </router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->

            <li v-if="phones && phones.length" class="phones dflt-link">
              <span class="title-categories-menu">{{ $static('menu.phones', 'Телефони') }}</span>
              <div class="header-info right-header-info">
                <template v-for="(value, key) in phones " :key="key">
                  <!-- <a :href="`tel:` + phoneValid" class="user-phone" v-if="phoneValid"></a> -->
                  <a :href="'tel:' + value.real" v-if="value.isNumber">{{ value.text }}</a>
                  <p v-else v-html="value.text">
                  </p>
                </template>
              </div>
            </li>

            <li class="dflt-link">
              <router-link :to="{ name: 'about' }">
                <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="!isCatalogMod">
              <router-link :to="{ name: 'delivery' }">
                <span class="text">{{ $static('menu.delivery', 'Доставка та оплата') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="!isCatalogMod">
              <router-link :to="{ name: 'policy' }">
                <span class="text">{{ $static('menu.policy', 'Політика конфіденційності') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="!isCatalogMod">
              <router-link :to="{ name: 'dostup' }">
                <span class="text">{{ $static('menu.dostup', 'Політика доступності') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="!isCatalogMod">
              <router-link :to="{ name: 'offerta' }">
                <span class="text">{{ $static('menu.offerta', 'Публічна оферта') }}</span>
              </router-link>
            </li>

          </ul>
        </div>
      </div>
      <div class="bottom-overlay-nav">
        <div class="chat-btn-holder mobile-hidden">
          <router-link class="chat-btn" :to="{ name: 'CabinetHistory' }">
            <img src="/images/icons/user-filled.svg" alt="">
            <span class="text">{{ $static('menu.cabinet', 'Кабінет') }}</span>
          </router-link>
        </div>

        <div class="chat-btn-holder" v-if="settings.consultant">
          <a :href="settings.consultant" class="chat-btn">
            <img src="/images/icons/telegram.svg" alt="">
            <span class="text">{{ $static('menu.mm', 'Зв’язок з менеджером') }}</span>
          </a>
        </div>


        <div class="menu-info-holder">
          <div class="menu-info time-info" v-if="s_info" v-html="s_info"></div>
        </div>
        <div class="overlay-lang-panel" v-if="lang?.length">
          <div class="lang-holder d-flex align-items-center">
            <div class="custom-label">{{ $static('menu.lng', 'Мова') }}:</div>
            <div class="lang-links">
              <a v-for="(l, index) in lang" :key="index" href="javascript:void(0)" @click="setLang(l)"
                :class="{ 'active': l.short === current.short }">
                {{ l.short }}
              </a>
              <!--              <a href="javascript:void(0)" @click="setLang('test')" :class="{ 'active': 'test' === current.short }">TEST</a>-->
            </div>
          </div>
        </div>
        <!--        <div class="soc-link">-->
        <!--          <a v-if="settings.instagram" :href="settings.instagram"># instagram</a>-->
        <!--        </div>-->
        <div class="develop-by" v-if="settings && settings.watter_mark">
          <a href="#">
            <div class="name"><img src="/images/developedby-dark.svg" alt="uxlove studio" class="developedby"></div>
            <img src="/images/uxlove-logo-dark.svg" alt="uxlove studio">
          </a>
        </div>
      </div>

    </div>
    <div class="open-nav" @click="handleCloseMenuBtnClick"></div>
    <div class="fixed-holder-btn" v-if="demoLink">
      <a href="javascript:void(0)" @click="openDemo" class="fixed-btn infinite animated pulse">
        <span class="btn-text">{{ demo_text }}</span>
      </a>
    </div>


    <MobileNavAndBasket />

    <MenuHeader @auth="fetchAccount" />

    <Categories></Categories>

    <router-view />
    <div class="personal-sidebar-bg"></div>
  </main>
  <footer>
    <div class="footer-holder">
      <div class="footer-contacts">
        <div class="holder">
          <h5>{{ $static('footer.tot-contacts', 'Контакти') }}</h5>
          <div class="contact-box">
            <div class="contact-item" v-if="settings.email">
              <div class="name">{{ $static('footer.email', 'Электронна пошта') }}:</div>
              <a :href="'mailto:' + settings.email" target="_top">{{ settings.email }}</a>
            </div>
            <div class="contact-item" v-if="settings.phone">
              <div class="name">{{ $static('footer.phone', 'Телефон') }}:</div>
              <!--              <a :href="'tel:'+settings.phone">{{settings.phone}}</a>-->
              <template v-for="(         value, key         ) in phones         " :key="key">
                <a :href="'tel:' + value.real" v-if="value.isNumber">
                  {{ value.text }}
                </a>
                <p v-else v-html="value.text">
                </p>
              </template>

            </div>

            <div class="contact-item" v-if="settings.socialNetworks && settings.socialNetworks.length">
              <div class="name">{{ $static('footer.social', 'Соціальні мережі') }}:</div>
              <a v-for="(socialNetwork, socialNetworkIndex) in settings.socialNetworks" :href="socialNetwork.link"
                :key="socialNetworkIndex" target="_blank" rel="noopener noreferrer">
                {{ socialNetwork.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-links footer-contact-links">
        <div class="holder">
          <ul>
            <li>
              <router-link :to="{ name: 'about' }">
                <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
              </router-link>
            </li>
            <li v-if="!isCatalogMod">
              <router-link :to="{ name: 'delivery' }">
                <span class="text">{{ $static('menu.delivery', 'Доставка та оплата') }}</span>
              </router-link>
            </li>
            <li v-if="!isCatalogMod">
              <router-link :to="{ name: 'policy' }">
                <span class="text">{{ $static('menu.policy', 'Політика конфіденційності') }}</span>
              </router-link>
            </li>
            <li v-if="!isCatalogMod">
              <router-link :to="{ name: 'dostup' }">
                <span class="text">{{ $static('menu.dostup', 'Політика доступності') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'offerta' }">
                <span class="text">{{ $static('menu.offerta', 'Публічна оферта') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CabinetHistory' }">
                <span class="text">{{ $static('menu.cabinet', 'Кабінет') }}</span>
              </router-link>
            </li>
          </ul>
          <div class="logos" v-if="!isCatalogMod">
            <img src="/images/icons/mastercard.svg" alt="mastercard">
            <img src="/images/icons/visa.svg" alt="visa">
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="copy">
          &copy; {{ $static('footer.copy', '2024 Копіювання матеріалів сайту без дозволу заборонено') }}
        </div>

        <div class="develop-by" v-if="settings && settings.watter_mark">

          <a href="https://ux.im/">
            <div class="name"><img src="/images/developedby.svg" alt="uxlove studio" class="developedby"></div>
            <img src="/images/uxlove-logo.svg" alt="uxlove studio">
          </a>
        </div>
      </div>
    </div>
  </footer>

  <!--  <div class="modal custom-modal fade" id="consult_modal" tabindex="-1" role="dialog" aria-hidden="true">-->
  <!--    <div class="modal-dialog modal-dialog-centered" role="document">-->
  <!--      <div class="modal-content">-->
  <!--        <div class="modal-header">-->
  <!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
  <!--            <img src="/images/icons/close.svg" alt="">-->
  <!--          </button>-->
  <!--        </div>-->
  <!--        <div class="modal-body">-->
  <!--          <h2>{{ $static('modal-callback.title', 'Отримати консультацію') }}</h2>-->
  <!--          <p>{{ $static('modal-callback.text', `Введіть свій номер телефону нижче,-->
  <!--            і наш менеджер зв’яжеться з Вами-->
  <!--            за декілька хвилин`) }}</p>-->
  <!--          <form>-->
  <!--            <div class="form-group" :class="{ 'error': errors['phone'] }">-->
  <!--              <input type="text" class="form-control custom-input" @focus="delError('phone')" placeholder="+380998787858"-->
  <!--                v-model="callbackPhone">-->
  <!--              <p class="help-text" v-for="(err, key) in errors['phone']" :key="key">{{ err }}</p>-->
  <!--            </div>-->
  <!--            <div class="button-holder">-->
  <!--              <a href="javascript:void(0)" class="default-btn" @click="sendCallback">-->
  <!--                <span class="btn-text">{{ $static('modal-callback.button', 'Отримати консультацію') }}</span>-->
  <!--                <img src="/images/icons/send-light.svg" alt="">-->
  <!--              </a>-->
  <!--            </div>-->
  <!--          </form>-->
  <!--          <div class="attention-apply">{{ $static('modal-callback.info1', 'При кліці на кнопку я погоджуюся з') }}-->
  <!--            <router-link :to="{ name: 'policy' }">{{ $static('modal-callback.info2', `політикою обробки персональних-->
  <!--              даних`) }}-->
  <!--            </router-link>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->


  <OnStartModal />
  <OnCloseModal />
  <!-- <div v-if="$route.path === '/'" class="frame-modal-wrapper-bg"></div> -->
</template>

<script>

import OnCloseModal from '@/components/OnCloseModal';
import OnStartModal from '@/components/OnStartModal';
import MobileNavAndBasket from '@/components/MobileNavAndBasket';
import Categories from '@/components/Categories';
import MenuHeader from '@/components/MenuHeader';

import { fixBodyPadding } from '@/utils/fixBodyPadding'

export default {
  name: 'Shop',
  components: { OnCloseModal, OnStartModal, MenuHeader, Categories, MobileNavAndBasket },

  data() {
    return {
      callbackPhone: '',
      errors: {},
      demoLink: undefined,
      demo_text: undefined,
      current: {
        icon: '/storage/i18n/ua.svg',
        title: 'Україньска',
        short: 'UA',
        value: '/storage/i18n/original.json'
      }
    }
  },

  methods: {
    async getUtmParams() {
      if (localStorage.getItem("utmParams")) {
        return
      }

      //router асинхронный, поэтому ждем
      await this.$router.isReady();

      // Определяем UTM-метки, которые нас интересуют
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
      const utmData = {};

      // Проверяем, есть ли UTM-метки в параметрах маршрута
      utmParams.forEach(param => {
        if (this.$route.query[param]) {
          utmData[param] = this.$route.query[param];
        }
      });

      // Если хотя бы одна метка есть, сохраняем их в localStorage
      if (Object.keys(utmData).length > 0) {
        localStorage.setItem('utmParams', JSON.stringify(utmData));
      }
    },

    categoryURL(category) {
      return category?.info?.url ? category.info.url : ''
    },
    getCategory(category) {
      category.hash = `x${category.level}x${category.id}`
      if (category.list?.length) {
        category.list = category.list.map(i => {
          i.root = category.root;
          i.breadcrumbs = [...category.breadcrumbs, `x${i.level}x${i.id}`];
          return i;
        }).map(this.getCategory).map(i => {
          i.parrent = category.hash;
          return i;
        })
      }
      return Object.assign(category, this.categories.find(x => x.id === category.id))
    },
    openDemo() {
      if (window.Telegram?.WebApp?.initData) {
        window.Telegram.WebApp.openTelegramLink(this.demoLink)
        window.Telegram.WebApp.close()
        return;
      }
      window.location.href = this.demoLink;
    },
    async setLang(lang) {
      if (lang === 'test') {
        this.current = {
          icon: '/storage/i18n/ua.svg',
          title: 'test',
          short: 'test',
          value: '/storage/i18n/test.json'
        }
        await this.fetchLang();
        return
      }
      this.current = lang;
      await this.fetchLang();
    },

    async fetchLang() {
      try {
        const response = await fetch(this.current.value);

        // Проверяем, успешен ли ответ
        if (!response.ok) {
          throw new Error(`Ошибка ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();

        if (data) {
          localStorage.setItem('$lang', this.current.short);
          this.$load(data, this.current.short);
        }
      } catch (error) {
        console.error('Ошибка при загрузке языка:', error);
        // Здесь можно добавить обработку ошибки, например, показать уведомление пользователю
      }
    },

    async fetchOrigin() {
      try {
        const response = await fetch('/storage/i18n/original.json');

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const data = await response.json();

        if (data) {
          this.$load(data, this.current.short);
        }
      } catch (error) {
        console.error('Ошибка при загрузке оригинальных данных:', error);
      }
    },
    delError(key) {
      delete this.errors[key]
    },
    validate() {
      this.errors = {};
      if (this.callbackPhone.length < 8) {
        this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
        return false
      }
      return true;
    },

    async fetchAccount() {
      let ok = false;
      const userInfo = localStorage.getItem('user_info');
      const headers = {};
      const initCache = localStorage.getItem('initData');

      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok = true;
      }

      const body = {};
      if (userInfo) {
        body.user = JSON.parse(userInfo);
        this.$store.commit('setUserInfo', body.user);
        ok = true;
      }

      if (!ok) {
        return Promise.resolve();
      }

      const searchParams = new URLSearchParams(window.location.search);
      const fbc = searchParams.has('fbclid')
        ? searchParams.get('fbclid')
        : localStorage.getItem('_fbc');

      if (fbc) {
        localStorage.setItem('_fbc', fbc);
        headers.FBC = fbc;
      }

      const fbp = localStorage.getItem('_fbp');
      if (fbp) {
        headers.FBP = fbp.replaceAll('_', '.');
      }

      try {
        const response = await fetch('/public/account', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: headers,
        });

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const json = await response.json();

        if (json && json.result) {
          this.$store.commit('setAccount', json.result);
        }

        if (json && json.file_count) {
          this.$store.commit('setFileCount', json.file_count);
        }

        if (json?.error) {
          window.localStorage.removeItem('user_info');
          window.localStorage.removeItem('initData');
        }
      } catch (error) {
        console.error('Ошибка при загрузке аккаунта:', error);
      }
    },

    async getPlugins() {
      try {
        const response = await fetch('/public/plugin');

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const data = await response.json();

        data.forEach(x => {
          const capitalizeType = x.type.charAt(0).toUpperCase() + x.type.slice(1);
          this.$store.commit(`set${capitalizeType}`, x.list);

          if (x.list.length) {
            const id = x.list[0].id;
            switch (x.type) {
              case 'payment':
                this.$store.commit('setDefaultPayment', id);
                break;
              case 'delivery':
                this.$store.commit('setDefaultDelivery', id);
                break;
            }
          }
        });
      } catch (error) {
        console.error('Ошибка при получении плагинов:', error);
      }
    },

    async getOptions() {
      try {
        const response = await fetch('/public/options');

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const data = await response.json();

        data.result.forEach(o => {
          switch (o.option_id) {
            case 'tags':
              this.$store.commit('setTags', o.list);
              break;
            case 'category':
              this.$store.commit('setCategories', o.list.map(category => {
                if (category.info?.groups) {
                  category.info.groups = category.info.groups.sort((a, b) => a.order - b.order);
                }
                return category;
              }));
              break;
            default:
              this.$store.commit('setOptions', o);
              break;
          }
        });

        if (data.settings) {
          this.$store.commit('setOptionSettings', data.settings);
        }
      } catch (error) {
        console.error('Ошибка при получении опций:', error);
      }
    },

    async fetchSettings() {
      const resp = await fetch('/public/settings');
      const data = await resp.json();
      if (data) {
        this.$store.commit('setSettings', data);
      }
    },

    async fetchPopup() {
      try {
        const response = await fetch('/public/templates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: ['modal_onstart', 'modal_onclose', 'home', 'menu'] })
        });

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Ошибка при загрузке шаблонов:', error);
      }
    },

    handleCloseMenuBtnClick() {
      fixBodyPadding();

      this.$store.commit('setBuskedOpen', false);

      window.$('.open-nav').removeClass('is-visible');
      window.$('.overlay-menu').removeClass('active');
      window.$('.show-menu-btn').removeClass('open');
      window.$('body').removeClass('no-scroll');

      const scrollElement = document.querySelector('.overlay-menu .menu-list');
      scrollElement.scrollTop = 0; //Возвращаем список пунктов бокового меню в начало при закрытии и после возможного скроллинга.
    },

    updateScrollStyles() {
      let bottomOverlayNavTop = Number(document.querySelector('.bottom-overlay-nav').offsetTop);

      const menuElementArray = Array.from(document.querySelectorAll('.dflt-link'));
      if (menuElementArray.length === 0) return; // Проверка на наличие элементов

      let firstMenuElementTop = menuElementArray[0].offsetTop;

      const scrollElement = document.querySelector('.overlay-menu .menu-list');
      if (!scrollElement) return; // Проверка на наличие элемента

      const scrollElementTop = Number(scrollElement.offsetTop);

      scrollElement.style.height = (bottomOverlayNavTop - scrollElementTop) - (scrollElementTop - firstMenuElementTop) + 'px';
    },
    onWindowLoad() {
      window.dispatchEvent(new Event('resize'));
    }
  },

  async mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    if (params.code) {
      this.$Code(params.code)
    }


    if (location.hostname === 'demo.ux.im') {
      this.demoLink = 'https://t.me/uxwebbot?start=demo'
      this.demo_text = this.$static('demo_btn_create.create', 'Створити безкоштовно')
    }
    if (location.hostname === 'ecommerce.ux.im') {
      this.demoLink = 'https://t.me/UXLoveBot?start=cloth_quiz'
      this.demo_text = this.$static('demo_btn_create.create2', 'Замовити в 1 клік')
    }

    document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
      // document.getElementById('subtitle').innerText = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', function (e) {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        }, { passive: false });
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }
      if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length) {
        localStorage.setItem('initData', window.Telegram.WebApp.initData);
        // this.initData = window.Telegram.WebApp.initData
      }
    }

    let options = await this.getOptions();
    let plugins = await this.getPlugins();
    let account = await this.fetchAccount();
    window.accountLoad = account;
    let settings = this.fetchSettings();
    let popUp = await this.fetchPopup();

    Promise.all([options, account, settings, plugins, popUp]).then((result) => {
      let data = result[4]
      if (data && data.length) {
        data.forEach(page => {
          if (page.name === 'home' && page.settings && !Array.isArray(page.settings.image)) {
            page.settings.image = [
              {
                url: page.settings.image,
                link: '/catalog/' + page.settings.url,
                title: page.settings.title,
                subtitle: page.settings.subtitle
              }
            ]
          }
          this.$store.commit('setPage', page)
        })
      }

      window.$('.preloader-page').removeClass('proccess-load')
    })

    // Слушатель события, определяющий будет ли скролл у списка бокового меню, если на него наезжает нижний элемент навигации, содержащий Кабинет и.т.д.. Актуален и для декстопа и мобильной версии
    window.addEventListener('load', this.onWindowLoad);    // Вызов функции после загрузке страницы
    window.addEventListener('resize', this.updateScrollStyles);
    // ________________________________________________________
    // document.querySelector(".open-nav").addEventListener('click', () => {
    //   this.handleCloseMenuBtnClick()
    // })

    await this.getUtmParams();
  },

  beforeUnmount() {
    // Удаляем слушатели событий перед уничтожением компонента
    window.removeEventListener('load', this.onWindowLoad);
    window.removeEventListener('resize', this.updateScrollStyles);
  },

  computed: {
    isContain() {
      return this.settings?.contain
    },
    isCatalogMod() {
      return this.settings?.catalog_mod ? this.settings?.catalog_mod : false
    },
    top() {
      return this.menu?.settings ? this.menu.settings.map(i => {
        i.root = `x${i.level}x${i.id}`;
        i.breadcrumbs = [i.root];
        return i;
      }).map(this.getCategory) : []
    },
    menu_type() {
      return this.$store.getters.getSettings ? this.$store.getters.getSettings.menu_type : undefined
    },
    menu() {
      return this.$store.getters.getPages ? this.$store.getters.getPages.menu : undefined
    },

    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    settings() {
      return this.$store.getters.getSettings
    },
    lang() {
      return this.settings ? this.settings.lang : this.settings
    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    initData() {
      return window.location.href
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    // phones() {
    //   return this.settings && this.settings.phone ?
    //     this.settings.phone.split(',').map(x => x.trim())
    //       .map(x => { return { real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+') } }) : false
    // },
    phones() {
      return this.settings && this.settings.phone ?
        this.settings.phone.split(',').map(x => x.trim())
          .map(x => { return { real: x, text: x, isNumber: x.startsWith('+') } }) : false
    },

    local() {
      return this.$local()
    },
    dynamics() {
      return this.$dynamics()
    },
    isReviewsView() {
      return this.$route.path === '/reviews' ? true : false;
    }
  },
  watch: {
    async lang(val) {
      if (val) {
        try {
          await this.fetchOrigin();
          const lang = localStorage.getItem('$lang');

          if (lang) {
            const x = val.find(i => i.short === lang);
            if (x) {
              this.current = x;
              await this.fetchLang();
            }
          }
        } catch (error) {
          console.error('Ошибка при обновлении языка:', error);
        }
      }
    }
  }

}
</script>