<template>
  <div class="tgme-wrapper">
    <div class="tgme-panel">
      <div class="logo" :style="{ 'background-image': $filters.getItemImage(settings.logo) }"></div>
      <div class="top-info">
        <div class="username">@{{ settings.BOT_NAME }}</div>
      </div>

      <div class="content">
        <p v-if="settings.previewText" v-html="settings.previewText"></p>
        <div class="catalog-item">
          <div class="item-holder" v-if="item">
            <div class="video-container" v-if="videos?.length">
              <a href="javascript:void(0)" t></a>
              <video :src="videos[0].url" aria-hidden="true" playsinline muted autoplay loop :type="videos[0].type"
                :poster="getItemImage(item)"></video>
            </div>
            <a v-else href="javascript:void(0)" class="image bg-cover"
              :style="{ 'background-image': $filters.getItemImage(item.preview) }"></a>
            <div class="item-content">
              <div class="product-name">
                <a href="javascript:void(0)" t>
                  {{ item.title }}
                </a>
              </div>
            </div>
            <div class="price-box">
              <div class="price-old" v-if="item.old_price">
                {{ $filters.money(item.old_price) }} {{ $store.getters.getSettings.symbol }}
              </div>
              <div class="price-element">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
            </div>
            <div class="btns">
              <div class="tgme-btn">
                <a href="javascript:void(0)" t>{{ $static('tgme.open-tg', 'Відкрити у Телеграмі') }}</a>
              </div>
              <div class="default-link">
                <router-link :to="{ name: 'Item', params: { category: 'main', id: id } }">
                  {{ $static('tgme.no-tg', 'Немає телеграму') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="item-holder" v-else>
            <div class="btns">
              <div class="tgme-btn">
                <a href="javascript:void(0)" t>
                  {{ $static('tgme.open-tg', 'Відкрити у Телеграмі') }}
                </a>
              </div>
              <div class="default-link">
                <router-link :to="{ name: 'home' }">
                  {{ $static('tgme.no-tg', 'Немає телеграму') }}
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tgme-bg" style="background-image:url('/images/tg-pattern.png');"></div>
    <div class="gradient"></div>
  </div>
</template>

<script>
export default {
  name: 'Subscribe',
  props: ['id'],
  data() {
    return {
      item: undefined
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    videos() {
      return this.item?.advanced?.videos?.length ? this.item.advanced.videos : undefined
    }
  },
  methods: {
    // fetchSettings() {
    //   return fetch('/public/settings').then(response => response.json()).then(
    //       data => {
    //         if (data) {
    //           this.$store.commit('setSettings', data);
    //         }
    //       })
    // },
    async fetchSettings() {
      try {
        const response = await fetch('/public/settings');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data) {
          this.$store.commit('setSettings', data);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    },

    // fetchItem(id) {
    //   return fetch('/public/item/' + id).then(response => response.json()).then(
    //     data => {
    //       if (data && data.result) {
    //         this.item = data.result;
    //       }
    //     })
    // },
    async fetchItem(id) {
      try {
        const response = await fetch(`/public/item/${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.result) {
          this.item = data.result;
        }
      } catch (error) {
        console.error('Error fetching item:', error);
        // Дополнительная обработка ошибок может быть добавлена здесь, если необходимо
      }
    },

    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    }
  },
  mounted() {
    let settings = this.fetchSettings();
    let hex = parseInt(this.id, 10).toString(16);
    let awaitList = [settings];
    if (this.id) {
      awaitList.push(this.fetchItem(this.id));
    }
    Promise.all(awaitList).then(() => {
      window.$('.preloader-page').removeClass('proccess-load');

      let addLink = '';
      let dLink = '/';
      if (this.settings.DIRECT_LINK) {
        dLink = this.settings.DIRECT_LINK;
      }
      let directLink = dLink.split('/');
      if (directLink.length === 5) {
        let bot_name = directLink[3];
        let app_name = directLink[4];
        addLink = `${bot_name}&appname=${app_name}` + `&startapp=t${hex}`;
      } else {
        addLink = this.settings.BOT_NAME;
      }

      Array.from(document.querySelectorAll('a[t]')).forEach(i => {
        i.href = 'tg://resolve?domain=' + addLink;
      });
      Array.from(document.querySelectorAll('a[t]'))[0].click();
    })
  },
  watch: {
    settings(val) {
      if (val && val.favicon) {
        let link = document.querySelector('link[rel~=\'icon\']');
        if (link) {
          link.href = val.favicon
        }
      }
    }
  }
}
</script>

<style scoped>
.logo {
  background-size: contain;
}

.video-container {
  text-align: center;
  position: relative;
}

.video-container a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 111;
}

.tgme-wrapper {
  min-height: 100vh;
  height: auto;
}

.video-container video {
  max-width: 100%;
  max-height: 100%;
  z-index: 11;
  width: 100%;
}
</style>