<template>
  <div class="category-panel" id="category_panel" v-if="menu_type === undefined">
    <div class="category-panel-holder">
      <div class="panel-name">{{ $static('categories.title', 'категорії') }}:</div>
      <ul class="categories-list" ref="simpleEle" @mousedown="(e) => mouseDownHandler(e, 'simpleEle')">
        <li>
          <router-link :to="{ name: 'home' }" :exact-active-class="'active'">
            <span class="text">{{ $static('menu.home', 'Головна') }}</span>
          </router-link>
        </li>

        <li v-for="(category, index) in categories" :key="'category-' + index">
          <router-link :to="'/catalog/' + category.info.url" :activeClass="'active'">
            <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <AdvancedMenu :categories="categories" :menu="menu" v-else-if="menu_type === 'advanced' && menu" />
</template>

<script>
import AdvancedMenu from '@/components/AdvancedMenu';

export default {
  name: 'Categories',
  components: { AdvancedMenu },

  data() {
    return {
      simpleEleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для ele
      },
    }
  },

  computed: {
    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    menu_type() {
      return this.$store.getters.getSettings ? this.$store.getters.getSettings.menu_type : undefined
    },
    menu() {
      return this.$store.getters.getPages ? this.$store.getters.getPages.menu : undefined
    }
  },

  methods: {
    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },
    mouseDownHandler(e, ref) {
      // console.log("mouseDownHandler");
      // console.log("ref", ref);

      // Определяем элемент для нужного списка
      const scrollElement = ref === 'simpleEleData' ? this.$refs.simpleEle : undefined;
      const data = ref === 'simpleEleData' ? this.simpleEleData : undefined;

      data.isMouseDown = true; // Устанавливаем флаг для активного списка
      data.sourceTargetClick = e.target;
      data.sourceTargetClickTime = new Date().getTime();
      data.pos = {
        left: scrollElement.scrollLeft,
        top: scrollElement.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      data.initialX = e.clientX;

      if (!this.isTouchDevice()) {
        scrollElement.style.userSelect = 'none';

        // Привязываем контекст для функций-обработчиков
        document.addEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
        document.addEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
      }
    },
    mouseMoveHandler(ref, e) {
      const data = ref === 'simpleEle' ? this.simpleEle : undefined;

      if (!data.isMouseDown) return; // Проверяем состояние для конкретного списка

      // console.log("mouseMoveHandler");

      const scrollElement = ref === 'simpleEle' ? this.$refs.simpleEle : undefined;

      const dx = e.clientX - data.pos.x;
      const dy = e.clientY - data.pos.y;

      scrollElement.scrollTop = data.pos.top - dy;
      scrollElement.scrollLeft = data.pos.left - dx;
    },
    mouseUpHandler(ref, e) {
      const data = ref === 'simpleEle' ? this.simpleEle : undefined;
      if (!data.isMouseDown) return;
      // console.log("mouseUpHandler");


      data.isMouseDown = false; // Сбрасываем флаг для конкретного списка

      const currentX = e.clientX;
      const xDifference = Math.abs(currentX - data.initialX);
      const now = new Date().getTime();

      if ((now - data.sourceTargetClickTime) < 500 && xDifference < 1) {
        let target = data.sourceTargetClick;
        while (target) {
          if (target.href) {
            this.$router.push(target.href.replaceAll(`${window.location.protocol}//${window.location.host}`, ""));
            break;
          }
          target = target.parentElement;
        }
      } else {
        document.addEventListener('click', this.preventClick, true);
      }
      // console.error("remove listeners")
      document.removeEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
      document.removeEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
    },
    preventClick(e) {
      // console.log("preventClick");
      e.stopImmediatePropagation();  // Останавливаем любое событие клика
      e.preventDefault();  // Отменяем стандартное действие
      document.removeEventListener('click', this.preventClick, true);  // Удаляем этот обработчик после срабатывания
    }
  },

  // mounted() {
  //   if (this.menu_type === undefined) {
  //     (function ($) {
  //       'use strict';
  // $('#category_panel').stick_in_parent({offset_top: 0});
  // $('#subcategory_panel').stick_in_parent({offset_top: 60});
  //     })(window.$);
  //   }
  // }

}
</script>
