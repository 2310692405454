<template>
  <div class="breadcrumb-holder desktop-breadcrumb" v-if="breadcrumbs.length">
    <ul class="breadcrumb">
      <li>
        <router-link :to="{ name: 'home' }" :exact-active-class="'active'">{{ $static('menu.home', 'Головна')
          }}</router-link>
      </li>
      <li v-for="(category, index) in breadcrumbs" :key="index">
        <router-link v-if="index < breadcrumbs.length - 1"
          :to="'/catalog/' + categoryURL(category) + `?hash=${category.hash}`" :activeClass="'active'"
          :class="{ 'with-level': category.list.length }">
          {{ $dynamic(category, category.name, 'name') }}
        </router-link>
        <span v-else>{{ $dynamic(category, category.name, 'name') }}</span>
      </li>
    </ul>
  </div>

  <div class="category-panel" id="category_panel">
    <div class="category-panel-holder">
      <div class="panel-name">{{ $static('categories.title', 'категорії') }}:</div>
      <ul class="categories-list" ref="ele" @mousedown="(e) => mouseDownHandler(e, 'ele')">
        <li>
          <router-link :to="{ name: 'home' }" :exact-active-class="'active'">
            <span class="text">{{ $static('menu.home', 'Головна') }}</span>
          </router-link>
        </li>

        <li v-for="(category, index) in top" :key="'category-' + index">
          <router-link :to="'/catalog/' + categoryURL(category) + `?hash=${category.hash}`"
            :class="{ 'active': root && root.hash === category.hash }">
            <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <div id="subcategory_panel" class="subcategory-panel" :class="{ 'has-level': selected.level > 0 }" v-if="selected">
    <div class="subcategory-holder">
      <div class="subcategory-back" v-if="selected.level > 0">
        <a href="javascript:void(0);" @click.prevent="selectParent">
          <img src="/images/icons/back.svg" alt="">
          <span class="text">{{ $static('menu.back', 'Назад') }}</span>
        </a>
      </div>
      <ul class="subcategory-list" ref="subEle" @mousedown="(e) => mouseDownHandler(e, 'subEle')">
        <li v-for="(category, index) in selected_list" :key="'subcategory-' + index">
          <router-link :to="'/catalog/' + categoryURL(category) + `?hash=${category.hash}`"
            :class="{ 'with-level': category.list.length, 'active': selected && category.hash === routerHash }">
            <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
            <img src="/images/icons/right.svg" alt="" v-if="category.list.length">
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <div class="breadcrumb-holder mobile-breadcrumb" v-if="breadcrumbs.length">
    <ul class="breadcrumb">
      <li>
        <router-link :to="{ name: 'home' }" :exact-active-class="'active'">{{ $static('menu.home', 'Головна')
          }}</router-link>
      </li>
      <li v-for="(category, index) in breadcrumbs" :key="index">
        <router-link v-if="index < breadcrumbs.length - 1"
          :to="'/catalog/' + categoryURL(category) + `?hash=${category.hash}`" :activeClass="'active'"
          :class="{ 'with-level': category.list.length }">
          {{ $dynamic(category, category.name, 'name') }}
        </router-link>
        <span v-else>{{ $dynamic(category, category.name, 'name') }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AdvancedMenu',
  props: ['categories', 'menu_type', 'menu'],
  data() {
    return {
      top: [],
      list: [],

      // Для первого списка (ele)
      eleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для ele
      },

      // Для второго списка (subEle)
      subEleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для subEle
      },
    }
  },

  computed: {
    router() {
      return this.$route
    },
    routerHash() {
      return this.router.query.hash
    },
    selected() {
      return this.routerHash ? this.list.find(x => x.hash === this.routerHash) : undefined
    },
    root() {
      return this.selected?.root ? this.list.find(x => x.hash === this.selected.root) : undefined
    },
    breadcrumbs() {
      return this.selected?.breadcrumbs ? this.selected.breadcrumbs.map(x => this.list.find(i => i.hash === x)) : []
    },
    parent() {
      return this.selected?.parrent ? this.list.find(x => x.hash === this.selected.parrent) : undefined
    },
    selected_list() {
      return this.selected?.list?.length ? this.selected?.list.filter(x => x.info?.url) : this.parent ? this.parent.list.filter(x => x.info?.url) : []
    }
  },

  methods: {
    categoryURL(category) {
      return category?.info?.url ? category.info.url : ''
    },
    unwrap(prev, current) {
      if (current.list?.length) {
        current.list.reduce(this.unwrap, prev)
      }
      prev.push(Object.assign({}, current))
      return prev
    },
    getCategory(category) {
      category.hash = `x${category.level}x${category.id}`
      if (category.list?.length) {
        category.list = category.list.map(i => {
          i.root = category.root;
          i.breadcrumbs = [...category.breadcrumbs, `x${i.level}x${i.id}`];
          return i;
        }).map(this.getCategory).map(i => {
          i.parrent = category.hash;
          return i;
        })
      }

      let found = this.categories.find(x => x.id === category.id);
      if (!found) {
        found = this.categories.find(x => x.name === category.name)
      }
      return Object.assign(category, found)
    },
    selectParent() {
      if (this.router.name === "ItemOrServiceItem") {
        this.$router.go(-1);
      } else {
        this.$router.push('/catalog/' + this.parent.info.url + `?hash=${this.parent.hash}`)
      }
    },
    calcMenu(value) {
      this.top = value.settings.map(i => {
        i.root = `x${i.level}x${i.id}`;
        i.breadcrumbs = [i.root];
        return i;
      }).map(x => this.getCategory(x))
      this.list = this.top.reduce(this.unwrap, []);
    },
    isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },
    mouseDownHandler(e, ref) {
      // Определяем элемент для нужного списка
      const scrollElement = ref === 'ele' ? this.$refs.ele : this.$refs.subEle;
      const data = ref === 'ele' ? this.eleData : this.subEleData;

      data.isMouseDown = true; // Устанавливаем флаг для активного списка
      data.sourceTargetClick = e.target;
      data.sourceTargetClickTime = new Date().getTime();
      data.pos = {
        left: scrollElement.scrollLeft,
        top: scrollElement.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      data.initialX = e.clientX;

      if (!this.isTouchDevice()) {
        scrollElement.style.userSelect = 'none';

        // Привязываем контекст для функций-обработчиков
        document.addEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
        document.addEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
      }

      // Остановка всплытия для subEle
      if (ref === 'subEle') {
        e.stopPropagation();
      }
    },
    mouseMoveHandler(ref, e) {
      const data = ref === 'ele' ? this.eleData : this.subEleData;

      if (!data.isMouseDown) return; // Проверяем состояние для конкретного списка

      const scrollElement = ref === 'ele' ? this.$refs.ele : this.$refs.subEle;

      const dx = e.clientX - data.pos.x;
      const dy = e.clientY - data.pos.y;

      scrollElement.scrollTop = data.pos.top - dy;
      scrollElement.scrollLeft = data.pos.left - dx;
    },
    mouseUpHandler(ref, e) {
      const data = ref === 'ele' ? this.eleData : this.subEleData;
      if (!data.isMouseDown) return;

      data.isMouseDown = false; // Сбрасываем флаг для конкретного списка

      const currentX = e.clientX;
      const xDifference = Math.abs(currentX - data.initialX);
      const now = new Date().getTime();

      if ((now - data.sourceTargetClickTime) < 500 && xDifference < 1) {
        let target = data.sourceTargetClick;
        while (target) {
          if (target.href) {
            this.$router.push(target.href.replaceAll(`${window.location.protocol}//${window.location.host}`, ""));
            break;
          }
          target = target.parentElement;
        }
      } else {
        document.addEventListener('click', this.preventClick, true);
      }

      document.removeEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
      document.removeEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
    },
    preventClick(e) {
      e.stopImmediatePropagation();  // Останавливаем любое событие клика
      e.preventDefault();  // Отменяем стандартное действие
      document.removeEventListener('click', this.preventClick, true);  // Удаляем этот обработчик после срабатывания
    }
  },

  mounted() {
    this.calcMenu(this.menu)

    if (this.selected) {
      this.$store.commit('setSearchBy', this.selected.search_by)

    }
  },

  watch: {
    menu(val) {
      this.calcMenu(val)
    },
    selected(val) {
      if (val) {
        this.$store.commit('setSearchBy', val.search_by)
      }
      // if (val) {
      // setTimeout(function (){
      //   'use strict';
      //   window.$('#subcategory_panel').stick_in_parent({offset_top: 60})
      // }, 1000)
      // }
    }
  }
}
</script>
