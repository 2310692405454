<template class="busked">

  <!-- ! Нижняя панель навигации для мобильной версии -->
  <div class="bottom-nav" :class="{ 'if-catalog': isCatalogMod }">
    <ul>
      <li :class="{ 'disable': !hasHistory }" class="nav-element">
        <a href="javascript:void(0)" @click="goBack">
          <img src="/images/icons/back.svg">
        </a>
      </li>
      <li class="nav-element">
        <router-link :to="{ name: 'home' }" :exact-active-class="'active'" class="home-button"
          @click="handleHomeBtnClick">
          <img src="/images/icons/Home.svg">
        </router-link>
      </li>
      <li class="quick-card-sticker-li nav-element" v-if="!isCatalogMod" @click="toggleBusked">
        <div id="quick_card_panel_sticker">
          <div class="quick-card-sticker-holder">
            <a href="javascript:void(0);">
              <span class="notification">
                {{ busked.length }}
              </span>
              <img src="/images/icons/bag-outline-menu.svg">
            </a>
            <div class="value">{{ $filters.money(total) }} {{ $store.getters.getSettings.symbol }}</div>
          </div>
        </div>
      </li>
      <li class="show-menu-li nav-element">
        <a href="javascript:void(0);" class="show-menu-btn" @click.prevent="handleShowMenuBtnClick">
          <span class="show-menu-btn-holder">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
      </li>
      <li class="login-trigger-item nav-element">
        <a href="javascript:void(0);" class="login-trigger" @click.prevent="handleLoginTriggerClick">
          <img src="/images/icons/user-filled.svg">
        </a>
      </li>
    </ul>
  </div>
  <!--!  ________________________________________ -->
  <!-- ! Всплывающее окно корзины заказов-->
  <div class="frame-modal-wrapper quick-cart" :class="{ 'opened': isBuskedOpen }" id="quick_cart">
    <div class="modal-wrapper-holder">
      <div class="frame-modal-header">
        <div class="name h2">{{ $static('busked.title', 'Ваша корзина') }}</div>
        <a href="javascript:void(0);" class="close-cart" @click="toggleBusked">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>
      <div class="frame-modal-body" v-if="busked.length">
        <ul class="frame-modal-list">
          <li class="cart-item" v-for="(item, index) in busked" :key="index">
            <div class="item-holder">
              <a class="remove">
                <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(order, index)">
              </a>
              <div class="item-info">
                <div class="image bg-cover" :style="{ 'background-image': $filters.getItemImage(item.preview) }"></div>
                <div class="right-side">
                  <router-link
                    :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id }, query: $route.query }">
                    {{ item.title }}
                  </router-link>


                  <!--                  <div class="name">{{item.title}}</div>-->
                  <div class="info-labels">
                    <span class="info-label">{{ $static('busked.count', 'Кількість:') }} <span>{{ item.count }}
                        {{ $static('busked.nam', 'шт') }}</span></span>
                  </div>
                  <div class="product-price" v-if="!settings.formula">
                    <div class="additional-line" v-if="item.price">
                      <div class="add-name">{{ $static('busked.price', 'Ціна') }}</div>
                      <!-- ? Сделать стили -->
                      <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                        {{ $store.getters.getSettings.symbol }}
                      </div>
                      <div class="price-element" :class="{ 'was-selled': !!item.price_old }"> {{
                        $filters.money(item.price)
                      }} {{ $store.getters.getSettings.symbol }}</div>
                      <!-- ? ------------------------ -->
                    </div>
                  </div>
                  <div class="additional" v-if="!settings.formula && item.additionSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                      <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                      <p class="value" v-if="m.price">{{ $filters.money(m.price) }} {{
                        $store.getters.getSettings.symbol }}
                      </p>
                    </div>
                  </div>
                  <div class="additional" v-if="!settings.formula && item.additionUSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                      <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                      <p class="value" v-if="m.price">{{ $filters.money(m.price) }} {{
                        $store.getters.getSettings.symbol }}
                      </p>
                    </div>
                  </div>
                  <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }}
                    {{ $store.getters.getSettings.symbol }}
                  </div>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
      <div class="frame-back-link">
        <a href="javascript:void(0)" class="back-link" @click.prevent="toggleBusked">
          <img src="/images/icons/left.svg">
          <span class="text">{{ $static('busked.continue', 'Продовжити покупки') }}</span>
        </a>
      </div>
      <div class="frame-modal-bottom">
        <div class="total">
          <span class="total-name">{{ $static('busked.total', 'Всього:') }}</span>
          <span class="total-value">{{ total }} <small>{{ $store.getters.getSettings.symbol }}</small></span>
        </div>
        <button class="default-btn trigger-to-order" @click="orderPage">
          <span class="btn-text">{{ $static('busked.order', 'Оформити') }}</span>
        </button>
      </div>
    </div>
  </div>
  <!--!  ________________________________________ -->

  <!-- ! Модалки статусов обработки действий в навигации-->
  <div class="thanks-modal" id="thx" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title', 'Дякуємо за покупку!') }}</div>
        <p>{{ $static('thanks.subtitle', 'Переходимо до оплати...') }}</p>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="thx-lead" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title_lead', 'Дякуємо за заявку!') }}</div>
        <p>{{ $static('thanks.subtitle_lead', 'Переходимо до історії заявок') }}</p>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="error" style="background: rgba(0,0,0,0.5);" @click="closeModal">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title_error', 'Увага!') }}</div>
        <p>{{ $static('thanks.subtitle_error2', 'Сталася помилка :(') }}</p>
      </div>
    </div>
  </div>
  <!-- 
  <div class="thanks-modal" id="errorModal" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.error', 'Сталася помилка') }}</div>
        <p>{{ $static('thanks.subtitle_error', 'Будь ласка перевірте ваші данні і спробуйте ще.') }}</p>
      </div>
    </div>
  </div> -->

  <!-- <div class="thanks-modal" id="paymentErrorModal" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.error', 'Сталася помилка') }}</div>
        <p>{{ $static('order.active-payment-error',
          'Для можливості зробити замовлення з обраним типом оплати необхідно авторизуватися') }}</p>
      </div>
    </div>
  </div> -->

  <!-- <div class="thanks-modal" id="orderOrLeadError" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.error', 'Сталася помилка') }}</div>
        <p>
          {{ $static('order.order-lead-error',
            'Відсутні доступні до замовлення товари та/або не обрані товари для оформлення заявки') }}</p>
      </div>
    </div>
  </div> -->
  <!--!  ________________________________________ -->

  <!--!  Кнопка корзины -->
  <template v-if="!isCatalogMod">
    <div class="quick-card-sticker" id="quick_card_sticker" @click="toggleBusked">
      <div class="quick-card-sticker-holder">
        <a href="javascript:void(0);">
          <span class="notification">
            {{ busked.length }}
          </span>
          <img src="/images/icons/bag-outline-menu.svg">
          <div class="value">{{ $filters.money(total) }} {{ $store.getters.getSettings.symbol }}</div>
        </a>
      </div>
    </div>
  </template>
  <!--!  ________________________________________ -->

  <!--!  Модалка личного кабинета для мобильной версии -->
  <div class="personal_area-sidebar through-personal-sidebar"><!--if not logged in add class .not-logged-->
    <div class="sidebar-name h2 desktop-hidden">{{ $static('cabinet.personal-title', 'Особистий кабінет') }}</div>
    <a href="javascript:void(0);" class="close-personal-sidebar desktop-hidden"
      @click.prevent="handleClosePersonalSidebarBtnClick">
      <img src="/images/icons/close.svg" alt="">
    </a>
    <div class="personal-area-sidebar-holder" v-if="userInfo">
      <div class="logged-in">
        <div class="logged-in-body">
          <span class="user-avatar image bg-cover" v-if="userInfo.photo_url"
            :style="{ 'background-image': 'url(' + userInfo.photo_url + ')' }"></span>
          <span class="user-avatar" v-else-if="userInfo.first_name">{{ userInfo.first_name.substring(0, 1) }}</span>
          <span class="user-avatar image bg-cover" v-else-if="userInfo.username">{{ userInfo.username.substring(0, 1)
            }}</span>
          <div class="avatar-info">
            <div class="name">{{ userInfo.first_name }} {{ userInfo.last_name }}</div>
            <div class="bonus-panel" v-if="account && !isCatalogMod">
              <span>{{ $static('busked.your-bonus', 'Ваші бонуси:') }} <span class="num">{{ bonus }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="personal_area-menu">
        <ul>
          <li>
            <router-link :to="{ name: 'CabinetHistory' }" :active-class="'active'">
              <span class="icons-holder">
                <img src="/images/icons/history.svg" alt="">
              </span>
              <span class="text">{{ $static('cabinet.title2', 'Історія замовлень') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CabinetLeadHistory' }" :active-class="'active'">
              <span class="icons-holder">
                <img src="/images/icons/history.svg" alt="">
              </span>
              <span class="text">{{ $static('cabinet.mm3', 'Історія заявок') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CabinetInfo' }" :exact-active-class="'active'">
              <span class="icons-holder">
                <img src="/images/icons/user.svg" alt="">
              </span>
              <span class="text">{{ $static('cabinet.title3', 'Особисті дані') }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="logout" v-if="!isTelegram && userInfo">
        <a href="javascript:void(0)" @click="logout">
          <span class="icons-holder">
            <img src="/images/icons/logout.svg" alt="">
          </span>
          <span class="text">{{ $static('cabinet.logout', 'Вийти з аккаунту') }}</span>
        </a>
      </div>
    </div>
    <div v-else>
      <p style="margin: 10px 15px;">{{
        $static(
          'cabinet.must-login',
          'Необхідна авторизація. Натисніть "Увійти як" - через телеграмм.'
        )
      }}</p>
    </div>
  </div>
  <!--!  ________________________________________ -->

  <div class="frame-modal-wrapper-bg cart-modal-wrapper-bg" @click="toggleBusked">
  </div>
  <div class="frame-modal-wrapper-bg mobile-menu-modal-wrapper-bg"></div>
  <div class="frame-modal-wrapper-bg personal-area-modal-wrapper-bg" @click="handleClosePersonalSidebarBtnClick"></div>

</template>

<script>
import { fixBodyPadding } from '@/utils/fixBodyPadding'

export default {
  name: 'Busked',

  mounted() {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.enableClosingConfirmation();
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(this.goBack);
    }
    (function ($) {
      'use strict';

      $(function () {
        if ($(window).scrollTop() >= 100) {
          $('.fixed-holder-btn').addClass('stickybottom');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 100) {
            $('.fixed-holder-btn').addClass('stickybottom');
          } else {
            $('.fixed-holder-btn').removeClass('stickybottom');
          }
        });
      });

      $(function () {
        if ($(window).scrollTop() >= 300) {
          $('.fixed-options-panel').addClass('stickybottom');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 300) {
            $('.fixed-options-panel').addClass('stickybottom');
          } else {
            $('.fixed-options-panel').removeClass('stickybottom');
          }
        });
      });
    })(window.$);
  },

  methods: {
    toggleBusked() {
      fixBodyPadding();

      this.$store.commit('setBuskedOpen', !this.isBuskedOpen)

      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.overlay-menu').removeClass('active');
      window.$('.open-nav').removeClass('is-visible');
      window.$('.show-menu-btn').removeClass('open');

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');


      window.$('.cart-modal-wrapper-bg').toggleClass('is-visible');
      window.$('.quick-card-sticker-li').toggleClass('active');
      window.$('#quick_cart').toggleClass('opened');

      if (window.$('.cart-modal-wrapper-bg').hasClass('is-visible')) {
        window.$('body').addClass('no-scroll');
      } else {
        window.$('body').removeClass('no-scroll');
      }
    },
    handleHomeBtnClick() {
      fixBodyPadding();

      this.$store.commit('setBuskedOpen', false);


      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.overlay-menu').removeClass('active');
      window.$('.open-nav').removeClass('is-visible');
      window.$('.show-menu-btn').removeClass('open');

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');


      window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
      window.$('.quick-card-sticker-li').removeClass('active');
      window.$('#quick_cart').removeClass('opened');

      window.$('body').removeClass('no-scroll');
    },

    handleShowMenuBtnClick() {
      this.$store.commit('setBuskedOpen', false);

      fixBodyPadding();
      // Обнуляем классы видимости, для того чтоб не было оставшихся видимых элементов при переходе между пунктами мобильного меню

      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');

      window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
      window.$('.quick-card-sticker-li').removeClass('active');
      window.$('#quick_cart').removeClass('opened');

      window.$('.overlay-menu').toggleClass('active');
      window.$('.open-nav').toggleClass('is-visible');
      window.$('.show-menu-btn').toggleClass('open');
      if (window.$('.overlay-menu').hasClass('active')) {
        window.$('body').addClass('no-scroll');
      } else {
        window.$('body').removeClass('no-scroll');
      }
    },

    handleLoginTriggerClick() {
      this.$store.commit('setBuskedOpen', false);

      fixBodyPadding();
      // Обнуляем классы видимости, для того чтоб не было оставшихся видимых элементов при переходе между пунктами мобильного меню
      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.overlay-menu').removeClass('active');
      window.$('.open-nav').removeClass('is-visible');
      window.$('.show-menu-btn').removeClass('open');

      window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
      window.$('.quick-card-sticker-li').removeClass('active');
      window.$('#quick_cart').removeClass('opened');

      // переключаем состояние видимости элементов Кабинета
      window.$('.personal-area-modal-wrapper-bg').toggleClass('is-visible');
      window.$('.personal_area-sidebar.through-personal-sidebar').toggleClass('opened');
      window.$('.login-trigger-item').toggleClass('active');

      if (window.$('.personal-area-modal-wrapper-bg').hasClass('is-visible')) {
        window.$('body').addClass('no-scroll');
      } else {
        window.$('body').removeClass('no-scroll');
      }
    },

    handleClosePersonalSidebarBtnClick() {
      this.$store.commit('setBuskedOpen', false);

      fixBodyPadding();

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');

      window.$('body').removeClass('no-scroll');
    },

    closeModal(event) {
      let target = event.target;
      while (target) {
        if (target.classList.contains('is-visible')) {
          target.classList.remove('is-visible')
          return
        }
        target = target.parentElement
      }
    },
    hasHistory() {
      return window.history.length > 2
    },
    goBack() {
      if (window.history.state.back) {
        this.$router.back()
      } else {
        window.Telegram.WebApp.close()
      }
      // Закрываем все панельки при клике на кнопку назад
      this.$store.commit('setBuskedOpen', false);

      fixBodyPadding();

      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.overlay-menu').removeClass('active');
      window.$('.open-nav').removeClass('is-visible');
      window.$('.show-menu-btn').removeClass('open');

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');


      window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
      window.$('.quick-card-sticker-li').removeClass('active');
      window.$('#quick_cart').removeClass('opened');

      window.$('body').removeClass('no-scroll');
    },

    async orderPage() {
      this.toggleBusked()
      this.$router.push({ name: 'Order' });
    },

    addition_price(item) {
      return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addition_Uprice(item) {
      return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    itemPrice(item) {
      return item.price + this.addition_price(item) + this.addition_Uprice(item)
    },
    delBasked(item, index) {
      this.$store.commit('delBasked', index)
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    logout() {
      localStorage.removeItem('user_info')
      localStorage.removeItem('initData')
      this.$store.commit('setAccount', undefined)
      this.$store.commit('setFileCount', { lead: 0, max_file: "101", review: 0 })
      this.$store.commit('setUserInfo', undefined)
      this.$router.push({ path: '/' })
      setTimeout(this.$router.go, 300)
    },
  },

  computed: {
    isTelegram() {
      if (window.Telegram && window.Telegram?.WebApp && window.Telegram.WebApp.initData && window?.Telegram?.WebApp?.initData.length > 0) { return true }
      else { return false }
    },
    isUserLoggedIn() {
      if (this.isTelegram) {
        return (window?.Telegram?.WebApp?.initData.length > 0) ? true : false;
      } else {
        return this.$store.getters.getUserInfo ? true : false;
      }
    },
    isBuskedOpen() {
      return this.$store.getters.getBuskedOpen
    },
    busked() {
      return this.$store.getters.getBusked
    },
    settings() {
      return this.$store.getters.getSettings
    },
    total() {
      return this.$store.getters.getBuskedTotal
    },
    userInfo() {
      return this.account?.telegram ? this.account?.telegram : this.account
    },
    account() {
      return this.$store.getters.getAccount
    },
    isCatalogMod() {
      return this.settings.catalog_mod
    },
    bonus() {
      return this.account?.bonus ? this.account.bonus : 0
    },
  }
}
</script>

<style scoped>
.nav-element.active {
  background-color: #212121;
}

li>a.active {
  background-color: #212121;
  width: 100%;
}

li a.home-button.active img {
  filter: invert(100)
}
</style>