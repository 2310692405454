<template>
  <div class="product-container">
    <div class="product-top-panel" v-if="menu_type === undefined">
      <router-link :to="{ name: 'home' }" class="back-link" v-if="category === 'main'">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5 10L7.14645 9.64645L6.79289 10L7.14645 10.3536L7.5 10ZM12.1464 4.64645L7.14645 9.64645L7.85355 10.3536L12.8536 5.35355L12.1464 4.64645ZM7.14645 10.3536L12.1464 15.3536L12.8536 14.6464L7.85355 9.64645L7.14645 10.3536Z"
            fill="black" />
        </svg>
        <span class="text">{{ $static('item.back', 'Назад в категорію') }}</span>
      </router-link>
      <router-link :to="{ name: 'catalog', params: { category: category } }" v-else class="back-link">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5 10L7.14645 9.64645L6.79289 10L7.14645 10.3536L7.5 10ZM12.1464 4.64645L7.14645 9.64645L7.85355 10.3536L12.8536 5.35355L12.1464 4.64645ZM7.14645 10.3536L12.1464 15.3536L12.8536 14.6464L7.85355 9.64645L7.14645 10.3536Z"
            fill="black" />
        </svg>
        <span class="text">{{ $static('item.back', 'Назад в категорію') }}</span>
      </router-link>
    </div>

    <div class="product-col-holder">
      <div class="product-images-wrapper">
        <div v-if="item"
          :class="{ 'product-images': item.images.length > 1 || videos?.length, 'product-image': item.images.length <= 1 }">
          <div class="product-label label-new" v-if="isHotItem">
            <span class="label-text">New</span>
          </div>
          <div class="product-label label-sale" v-if="item.price_old && getDiscount(item) > 0">
            <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
          </div>
          <ul class="image_list" ref="itemImageEle" @mousedown="(e) => mouseDownHandler(e, 'itemImageEle')">
            <li v-for="(video, index) in videos" :key="index" class="videoBox">
              <video :src="video.url" aria-hidden="true" playsinline controls muted autoplay loop
                :type="video.type"></video>
            </li>
            <li v-for="(image, index) in item.images" :key="index">
              <a :href="`${getURLImage(image)}`" @click="fancyClick" data-fancybox="zoom" class="image bg-cover"
                :style="{ 'background-image': $filters.getItemImage(image) }"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="product-wrapper wrapper" v-if="item">
        <div class="product-top-info d-flex align-items-center">
          <div class="availability-holder">
            <div class="availability not-availability" v-if="!isStock">
              <span>{{ $static('item.nema', 'Нема в наявності') }} </span>
            </div>
            <div class="availability in-stock" v-else>
              <span>{{ $static('item.est', 'В наявності') }}</span>
            </div>
          </div>
          <div class="product-id" v-if="item.article">
            <div class="id-name">
              {{ $static('item.art-id', 'Артикул:') }}
              <span class="id-value">{{ item.article }}</span>
            </div>
          </div>
        </div>

        <div class="name h1">{{ $dynamic(item, item.title, 'title') }}</div>

        <div class="related-products groups-relate" v-if="group?.length && categories?.length">
          <ul class="catalog-grid" ref="itemCatalogEle" @mousedown="(e) => mouseDownHandler(e, 'itemCatalogEle')">
            <li class="catalog-item" v-for="(item, index) in group" :key="index">
              <router-link
                :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id }, query: $route.query }"
                class="group-relate-link" @click.prevent.stop="">
              </router-link>
              <div class="item-holder">
                <div class="product-label label-new" v-if="isHot(item)">
                  <span class="label-text">New</span>
                </div>
                <div class="product-label label-sale" v-if="item.price_old && getDiscount(item) > 0">
                  <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
                </div>
                <div class="image bg-cover" :style="`background-image: url(${getItemImage(item)});`"></div>
                <div class="product-name">
                  {{ $dynamic(item, item.title, 'title') }}
                </div>
                <div class="options">
                  <div class="price-box">
                    <div class="price-element" v-if="item.price" :class="{ 'was-selled': !!item.price_old }">{{
                      $filters.money(item.price)
                    }} {{ $store.getters.getSettings.symbol }}</div>
                    <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                      {{ $store.getters.getSettings.symbol }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <ItemUniqueOption v-for="(option, index) in uOptions" :key="index" :option_key="index" :option="option"
          @select="selectuOption" />

        <ItemOption v-for="(option, index) in options" :key="index" :option_key="index" :option="option"
          :settings="optionSettings" @select="selectOption" />

        <div class="particular" v-if="ingredient.length > 0">
          <div class="custom-label">
            {{ $static('item.badge-title', 'Особливості') }}
          </div>
          <ul>
            <li v-for="(badge, badgeIndex) in ingredient" :key="badge + badgeIndex">
              <img v-if="badge.image" :src="badge.image" :alt="badge.name" class="label-pic">
              <span v-if="badge.color && !badge.image"
                :style="{ 'backgroundColor': badge.color, 'display': 'inline-block' }" class="label-pic"></span>
              <span class="text">{{ $dynamic(badge, badge.name, 'name') }}</span>
            </li>
          </ul>
        </div>
        <div class="product-buy-options" :class="{ 'calc-box': !settings.formula && addPrice }">
          <div class="price-box d-flex">
            <div class="price-element" v-if="addPrice" :class="{ 'was-selled': !!item.price_old }">
              <template v-if="!settings.formula">{{ $filters.money(itemPrice * order.count) }} + {{
                $filters.money(addPrice * order.count) }} =
              </template>
              {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}
            </div>
            <div class="price-element" v-else-if="item.price" :class="{ 'was-selled': !!item.price_old }"> {{
              $filters.money(item.price
                * order.count)
            }} {{ $store.getters.getSettings.symbol }}</div>
            <div class="price-old" v-if="item.price_old && item.price"> {{ $filters.money(price_old) }}
              {{ $store.getters.getSettings.symbol }}
            </div>
          </div>
          <div class="nt-spinner">
            <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(-1)"></button>
            <input type="text" class="form-control " v-model="order.count">
            <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(+1)"></button>
          </div>
        </div>



        <div class="buttons product-buttons">
          <button class="default-btn quick_cart_trigger" @click="buy" :disabled="!isStock"
            :class="{ 'not-in-stock': !isStock }">
            <span class="btn-text">{{ $static('item.buy', 'До кошика') }}</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.25 7.08331L6.25 6.66664C6.25 4.59558 7.92893 2.91665 10 2.91664V2.91664C12.0711 2.91664 13.75 4.59557 13.75 6.66664L13.75 7.08331"
                stroke="white" stroke-linecap="round" />
              <path d="M12.9167 11.25V9.58331" stroke="white" stroke-linecap="round" />
              <path d="M7.08333 11.25V9.58331" stroke="white" stroke-linecap="round" />
              <path
                d="M4.58333 10.6833C4.58333 8.98626 4.58333 8.13773 5.11054 7.61052C5.63774 7.08331 6.48627 7.08331 8.18333 7.08331H11.8167C13.5137 7.08331 14.3622 7.08331 14.8895 7.61052C15.4167 8.13773 15.4167 8.98626 15.4167 10.6833V11.6666C15.4167 13.9324 15.4167 15.0653 14.836 15.8572C14.655 16.104 14.4374 16.3217 14.1906 16.5026C13.3987 17.0833 12.2658 17.0833 9.99999 17.0833V17.0833C7.73422 17.0833 6.60133 17.0833 5.8094 16.5026C5.5626 16.3217 5.34495 16.104 5.16399 15.8572C4.58333 15.0653 4.58333 13.9324 4.58333 11.6666V10.6833Z"
                stroke="white" />
            </svg>
          </button>

          <a v-if="consultant" :href="consultant" class="default-btn btn-outline ">
            <span class="btn-text">{{ $static('item.consult', 'Консультант') }}</span>
            <img src="/images/icons/reviews.svg">
          </a>
        </div>

        <Teleport to="body">
          <div class="fixed-options-panel" v-if="isStock">
            <div class="product-buy-options">
              <div class="price-box">
                <!-- <div class="price-element" v-if="addPrice" :class="{ 'was-selled': !!item.price_old }">
                  <template v-if="!settings.formula">{{ $filters.money(itemPrice * order.count) }} + {{
                    $filters.money(addPrice * order.count) }} =
                  </template>
                  {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}
                </div> -->
                <div class="price-element" :class="{ 'was-selled': !!item.price_old }"> {{ $filters.money(price) }} {{
                  $store.getters.getSettings.symbol }}</div>
                <div class="price-old" v-if="item.price_old"> {{ $filters.money(price_old) }}
                  {{ $store.getters.getSettings.symbol }}
                </div>
              </div>

              <div class="btns d-flex">
                <button class="default-btn quick_cart_trigger" @click="buy">
                  <span class="btn-text">{{ $static('item.buy', 'До кошика') }}</span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.25 7.08331L6.25 6.66664C6.25 4.59558 7.92893 2.91665 10 2.91664V2.91664C12.0711 2.91664 13.75 4.59557 13.75 6.66664L13.75 7.08331"
                      stroke="white" stroke-linecap="round" />
                    <path d="M12.9167 11.25V9.58331" stroke="white" stroke-linecap="round" />
                    <path d="M7.08333 11.25V9.58331" stroke="white" stroke-linecap="round" />
                    <path
                      d="M4.58333 10.6833C4.58333 8.98626 4.58333 8.13773 5.11054 7.61052C5.63774 7.08331 6.48627 7.08331 8.18333 7.08331H11.8167C13.5137 7.08331 14.3622 7.08331 14.8895 7.61052C15.4167 8.13773 15.4167 8.98626 15.4167 10.6833V11.6666C15.4167 13.9324 15.4167 15.0653 14.836 15.8572C14.655 16.104 14.4374 16.3217 14.1906 16.5026C13.3987 17.0833 12.2658 17.0833 9.99999 17.0833V17.0833C7.73422 17.0833 6.60133 17.0833 5.8094 16.5026C5.5626 16.3217 5.34495 16.104 5.16399 15.8572C4.58333 15.0653 4.58333 13.9324 4.58333 11.6666V10.6833Z"
                      stroke="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Teleport>
      </div>

    </div>
    <div class="product-info wrapper" v-if="item">
      <div class="custom-tabs">
        <ul class="nav nav-pills " id="product-tabs" role="tablist">

          <li class="nav-item" v-if="item.description && item.description.length > 7">
            <a class="nav-link active" data-toggle="pill" href="#description_" role="tab" aria-selected="true">
              {{ $static('item.op1', 'Опис') }}
            </a>
          </li>

          <li class="nav-item" v-if="item.advanced && item.advanced.text1 && item.advanced.text1.length > 7">
            <a class="nav-link" :class="{ 'active': !item.description || !item.description.length > 7 }"
              data-toggle="pill" href="#tab_2" role="tab" aria-selected="true">
              {{ $static('item.text1', 'Характеристики') }}
            </a>
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">

          <div class="tab-pane fade show active" id="description_" role="tabpanel" v-if="item.description">
            <div class="panel-body">
              <div class="text" v-html="shortItemDescription.html">
              </div>
              <div class="info-more-label" v-if="shortItemDescription.isReadMore">
                <a class="show-more-label" @click="openReadMore('description')">
                  <span class="text">{{ $static('item.more-inf-btn', 'Читати більше') }}</span>
                </a>
              </div>
            </div>
          </div>

          <div class="tab-pane fade"
            :class="{ 'show': !item.description || !item.description.length > 7, 'active': !item.description || !item.description.length > 7 }"
            id="tab_2" role="tabpanel" v-if="item.advanced && item.advanced.text1">
            <div class="panel-body">
              <div class="text" v-html="shortItemCharacteristics.html"></div>
              <div class="info-more-label" v-if="shortItemCharacteristics.isReadMore">
                <a class="show-more-label" @click="openReadMore('сharacteristics')">
                  <span class="text">{{ $static('item.more-inf-btn', 'Читати більше') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="related-products" v-if="upsaleItems && upsaleItems.length">
      <div class="name h2">{{ $static('item.related', 'З цим часто купують:') }}</div>
      <ul class="catalog-grid" ref="itemCatalog2ele" @mousedown="(e) => mouseDownHandler(e, 'itemCatalog2ele')">
        <li class="catalog-item" v-for="(item, index) in upsaleItems" :key="index">
          <div class="item-holder" v-if="categories?.length">
            <div class="product-label label-new" v-if="isHot(item)">
              <span class="label-text">New</span>
            </div>
            <div class="product-label label-sale" v-if="item.price_old && getDiscount(item) > 0">
              <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
            </div>
            <router-link
              :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id }, query: $route.query }"
              class="image bg-cover" @click.prevent.stop=""
              :style="{ 'background-image': $filters.getItemImage(item.preview) }"></router-link>
            <div class="product-name">
              <router-link
                :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id }, query: $route.query }">
                {{ $dynamic(item, item.title, 'title') }}
              </router-link>

            </div>
            <div class="options">
              <div class="price-box">
                <div class="price-element" :class="{ 'was-selled': !!item.price_old }"> {{ $filters.money(item.price) }}
                  {{
                    $store.getters.getSettings.symbol }}</div>
                <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                  {{ $store.getters.getSettings.symbol }}
                </div>
              </div>
              <div class="right-options">
                <router-link
                  :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id }, query: $route.query }"
                  class="default-btn btn-icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.25 7.08331L6.25 6.66664C6.25 4.59558 7.92893 2.91665 10 2.91664V2.91664C12.0711 2.91664 13.75 4.59557 13.75 6.66664L13.75 7.08331"
                      stroke="white" stroke-linecap="round" />
                    <path d="M12.9167 11.25V9.58331" stroke="white" stroke-linecap="round" />
                    <path d="M7.08333 11.25V9.58331" stroke="white" stroke-linecap="round" />
                    <path
                      d="M4.58333 10.6833C4.58333 8.98626 4.58333 8.13773 5.11054 7.61052C5.63774 7.08331 6.48627 7.08331 8.18333 7.08331H11.8167C13.5137 7.08331 14.3622 7.08331 14.8895 7.61052C15.4167 8.13773 15.4167 8.98626 15.4167 10.6833V11.6666C15.4167 13.9324 15.4167 15.0653 14.836 15.8572C14.655 16.104 14.4374 16.3217 14.1906 16.5026C13.3987 17.0833 12.2658 17.0833 9.99999 17.0833V17.0833C7.73422 17.0833 6.60133 17.0833 5.8094 16.5026C5.5626 16.3217 5.34495 16.104 5.16399 15.8572C4.58333 15.0653 4.58333 13.9324 4.58333 11.6666V10.6833Z"
                      stroke="white" />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="frame-modal-wrapper more-info-product" :class="{ 'opened': readMoreOpen }" id="more_info_product">
    <div class="modal-wrapper-holder" v-if="selectedPillType === 'сharacteristics'">
      <div class=" frame-modal-header">
        <div class=" name h2">{{ $static('item.сharact', 'Характеристики товару:') }}</div>
        <a href="javascript:void(0);" @click="closeReadMore" class="close-cart">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>
      <div class="frame-modal-body" v-if="item">
        <div class="info-text" v-html="$dynamic(item, item?.advanced?.text1, 'сharacteristics')">
        </div>
      </div>
    </div>

    <div class="modal-wrapper-holder" v-else>
      <div class="frame-modal-header">
        <div class=" name h2">{{ $static('item.more-inf', 'Опис товару:') }}</div>
        <a href="javascript:void(0);" @click="closeReadMore" class="close-cart">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>
      <div class="frame-modal-body" v-if="item">
        <div class="info-text" v-html="$dynamic(item, item.description, 'description')">
        </div>
      </div>
    </div>
  </div>

  <div class="frame-modal-wrapper-bg read-more-modal-wrapper-bg" :class="{ 'is-visible': readMoreOpen }"
    @click="closeReadMore"></div>
</template>

<script>
import ItemUniqueOption from '@/components/ItemUniqueOption';
import ItemOption from '@/components/ItemOption';
import { fixBodyPadding } from '@/utils/fixBodyPadding'

export default {
  name: 'Item',
  components: { ItemUniqueOption, ItemOption },
  props: ['category', 'id'],
  data() {
    return {
      item: undefined,
      upsaleItems: [],
      group: [],
      selectedPillType: "description",
      order: {
        count: 1,
        additionSelect: [],
        additionUSelect: []
      },

      itemImageEleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для ele
      },
      itemCatalogEleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для ele
      },
      itemCatalog2EleData: {
        pos: { top: 0, left: 0, x: 0, y: 0 },
        initialX: 0,
        sourceTargetClick: null,
        sourceTargetClickTime: null,
        isMouseDown: false, // Флаг для отслеживания состояния мыши для ele
      },
    }
  },
  async mounted() {
    await this.getItem();

    window.scrollTo(0, 0);
    window.$('.product-images a').fancybox();
    // window.$(`a[href="/catalog/${this.category}"]`).addClass('active');

    // window.$('.item-modal-wrapper-bg').on('click', function () {
    //   window.$(this).removeClass('is-visible');
    //   window.$('body').removeClass('no-scroll');

    // window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
    //   // window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
    //   return false;
    // });
  },
  methods: {
    openReadMore(pillType) {
      this.selectedPillType = pillType;
      fixBodyPadding();
      this.$store.commit('setReadMoreOpen', true);
      window.$('body').addClass('no-scroll');
    },

    closeReadMore() {
      fixBodyPadding();
      this.$store.commit('setReadMoreOpen', false);
      window.$('body').removeClass('no-scroll');
    },

    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    addCount(i) {
      if (i + this.order.count) {
        this.order.count = Math.max(this.order.count + i, 1)
      }
    },

    isHot(item) {
      return item.advanced && item.advanced.hot
    },

    selectOption({ item, status }) {
      if (!this.order.additionSelect) {
        this.order.additionSelect = []
      }
      if (status) {
        this.order.additionSelect.push(item)
      } else {
        let idx = this.order.additionSelect.findIndex(x => x.id == item.id);
        if (idx > -1) {
          this.order.additionSelect.splice(idx, 1)
        }
      }
    },

    selectuOption({ item, status }) {
      if (!this.order.additionUSelect) {
        this.order.additionUSelect = []
      }
      if (status) {
        this.order.additionUSelect.push(item)
      } else {
        let idx = this.order.additionUSelect.findIndex(x => x._id == item._id);
        if (idx > -1) {
          this.order.additionUSelect.splice(idx, 1)
        }
      }
    },

    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },

    getDiscount(item) {
      return item.preview ? (1 - Math.max(item.price / item.price_old, 0)) * 100 | 0 : ''
    },

    categoryURL(item) {
      let category = this.categories.find(x => x.id == item.category);
      return category?.info?.url ? category.info.url : ''
    },

    buy() {
      let idx = -1;
      idx = this.basked.findIndex(i => i.id === this.item.id);
      if (idx > -1) {
        // this.basked[idx].count += this.order.count;
        this.$store.commit(
          'replaceBuskedIndex',
          { index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item))) }
        );
      } else {
        let item = JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))
        this.$store.commit('addToBasked', item);
        this.$AddToCart(item)
      }
      this.toggleBusked()
      // fixBodyPadding();
      // window.$('body').addClass('no-scroll');
      // window.$('#quick_cart').addClass('opened');
      // window.$('.item-modal-wrapper-bg').addClass('is-visible');
    },

    toggleBusked() {
      fixBodyPadding();

      this.$store.commit('setBuskedOpen', !this.isBuskedOpen)

      this.$store.commit('setSearchOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      window.$('.overlay-menu').removeClass('active');
      window.$('.open-nav').removeClass('is-visible');
      window.$('.show-menu-btn').removeClass('open');

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');


      window.$('.cart-modal-wrapper-bg').toggleClass('is-visible');
      window.$('.quick-card-sticker-li').toggleClass('active');
      window.$('#quick_cart').toggleClass('opened');

      window.$('body').toggleClass('no-scroll');
    },

    getURLImage(image) {
      if (image && image.includes('https://')) {
        return image
      }
      return `${window.location.protocol}//${window.location.host}${image}`
    },

    async getItem() {
      this.group = [];
      try {
        const response = await fetch(`/public/item/${this.id}`);
        const data = await response.json();

        if (data.result) {
          this.item = data.result;
          this.$ViewItem(this.item);
          await this.getUpsaleItems();
        }

        if (data.group) {
          this.group = data.group;
        }

        if (data.error === 'not found') {
          this.$router.push({ name: 'NotFound' });
          return;
        }

        if (this.item && this.item.advanced) {
          const title = this.item.advanced.meta_title || this.$dynamic(this.item, this.item.title, 'title');
          document.querySelector('title').innerText = title;
          document.querySelector('meta[property="og:title"]').setAttribute('content', title);

          const description = this.item.advanced.meta_description || '';
          document.querySelector('meta[name="description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:description"]').setAttribute('content', description);

          const image = this.item.preview ? `${window.location.protocol}//${window.location.host}${this.item.preview}` : '';
          document.querySelector('meta[property="og:image"]').setAttribute('content', image);
        }
      } catch (error) {
        console.error("Error fetching item:", error);
      }
    },

    async getUpsaleItems() {
      if (this.upsale && this.upsale.length) {
        try {
          const response = await fetch(`/public/item`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ list: this.upsale.map(i => i.id) })
          });

          const data = await response.json();

          if (data.result) {
            this.upsaleItems = data.result;
          }
        } catch (error) {
          console.error("Error fetching upsale items:", error);
        }
      }
    },

    isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },

    mouseDownHandler(e, ref) {
      // Определяем элемент для нужного списка
      let scrollElement;
      let data;

      switch (ref) {
        case 'itemCatalog2Ele':
          scrollElement = this.$refs.itemCatalog2Ele; // Ссылка на первый список
          data = this.itemCatalog2EleData; // Данные для первого списка
          break;
        case 'itemCatalogEle':
          scrollElement = this.$refs.itemCatalogEle; // Ссылка на подсписок
          data = this.itemCatalogEleData; // Данные для подсписка
          break;
        case 'itemImageEle':
          scrollElement = this.$refs.itemImageEle; // Пример, нужно убедиться, что у вас есть этот ref
          data = this.itemImageEleData; // Данные для элемента изображения (предположим, что у вас есть такая переменная)
          break;
        default:
          scrollElement = undefined;
          data = undefined;
          console.error(`Unknown ref: ${ref}`);
      }
      data.isMouseDown = true; // Устанавливаем флаг для активного списка
      data.sourceTargetClick = e.target;
      data.sourceTargetClickTime = new Date().getTime();
      data.pos = {
        left: scrollElement.scrollLeft,
        top: scrollElement.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      data.initialX = e.clientX;

      if (!this.isTouchDevice()) {
        scrollElement.style.userSelect = 'none';

        // Привязываем контекст для функций-обработчиков
        document.addEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
        document.addEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
      }
    },

    mouseMoveHandler(ref, e) {
      let data;
      let scrollElement;

      switch (ref) {
        case 'itemCatalog2Ele':
          scrollElement = this.$refs.itemCatalog2Ele; // Ссылка на первый список
          data = this.itemCatalog2EleData; // Данные для первого списка
          break;
        case 'itemCatalogEle':
          scrollElement = this.$refs.itemCatalogEle; // Ссылка на подсписок
          data = this.itemCatalogEleData; // Данные для подсписка
          break;
        case 'itemImageEle':
          scrollElement = this.$refs.itemImageEle; // Пример, нужно убедиться, что у вас есть этот ref
          data = this.itemImageEleData; // Данные для элемента изображения (предположим, что у вас есть такая переменная)
          break;
        default:
          scrollElement = undefined;
          data = undefined;
          console.error(`Unknown ref: ${ref}`);
      }

      if (!data.isMouseDown) return; // Проверяем состояние для конкретного списка

      // console.log("mouseMoveHandler");

      const dx = e.clientX - data.pos.x;
      const dy = e.clientY - data.pos.y;

      scrollElement.scrollTop = data.pos.top - dy;
      scrollElement.scrollLeft = data.pos.left - dx;
    },

    mouseUpHandler(ref, e) {
      let data;
      let scrollElement;

      switch (ref) {
        case 'itemCatalog2Ele':
          scrollElement = this.$refs.itemCatalog2Ele; // Ссылка на первый список
          data = this.itemCatalog2EleData; // Данные для первого списка
          break;
        case 'itemCatalogEle':
          scrollElement = this.$refs.itemCatalogEle; // Ссылка на подсписок
          data = this.itemCatalogEleData; // Данные для подсписка
          break;
        case 'itemImageEle':
          scrollElement = this.$refs.itemImageEle; // Пример, нужно убедиться, что у вас есть этот ref
          data = this.itemImageEleData; // Данные для элемента изображения (предположим, что у вас есть такая переменная)
          break;
        default:
          scrollElement = undefined;
          data = undefined;
          console.error(`Unknown ref: ${ref}`);
      }

      if (!data.isMouseDown) return;
      // console.log("mouseUpHandler");
      data.isMouseDown = false; // Сбрасываем флаг для конкретного списка

      const currentX = e.clientX;
      const xDifference = Math.abs(currentX - data.initialX);
      const now = new Date().getTime();

      if ((now - data.sourceTargetClickTime) < 500 && xDifference < 1) {
        let target = data.sourceTargetClick;
        while (target) {
          if (target.href) {
            if (scrollElement.classList.contains('image_list')) { break }
            this.$router.push(target.href.replaceAll(`${window.location.protocol}//${window.location.host}`, ""));
            break;
          }
          target = target.parentElement;
        }
      } else {
        document.addEventListener('click', this.preventClick, true);
      }
      // console.error("remove listeners")
      document.removeEventListener('mousemove', (event) => this.mouseMoveHandler(ref, event));
      document.removeEventListener('mouseup', (event) => this.mouseUpHandler(ref, event));
    },
    preventClick(e) {
      // console.log("preventClick");
      e.stopImmediatePropagation();  // Останавливаем любое событие клика
      e.preventDefault();  // Отменяем стандартное действие
      document.removeEventListener('click', this.preventClick, true);  // Удаляем этот обработчик после срабатывания
    },

    truncateText(text, maxLength = 500) {
      if (text) {
        return text.length <= maxLength ? text : text.slice(0, maxLength) + "...";
      }
      return "";
    }
  },

  computed: {
    menu_type() {
      return this.$store.getters.getSettings ? this.$store.getters.getSettings.menu_type : undefined
    },
    videos() {
      return this.item?.advanced?.videos?.length ? this.item.advanced.videos : undefined
    },
    consultant() {
      return this.settings && this.settings.consultant ? this.settings.consultant : false
    },
    categories() {
      return this.$store.getters.getCategories
    },
    settings() {
      return this.$store.getters.getSettings
    },
    options() {
      return this.item && this.item.advanced && this.item.advanced.options ? this.item.advanced.options : []
    },
    uOptions() {
      return this.item && this.item.advanced && this.item.advanced.uOption ? this.item.advanced.uOption : []
    },
    optionSettings() {
      return this.$store.getters.getOptionSettings ? this.$store.getters.getOptionSettings : []
    },
    ingredient() {
      return this.item?.advanced?.options?.ingredient ? this.item?.advanced?.options?.ingredient.map(id => (
        this.ingridients?.list?.find(x => x.id === id)
      )).filter(i => !!i).sort((a, b) => a.order - b.order) : []
    },
    ingridients() {
      return this.$store.getters.getOptions?.ingredient ? this.$store.getters.getOptions.ingredient : {}
    },
    itemPrice() {
      return this.item.price
    },
    addPrice() {
      return this.addition_price + this.addition_Uprice
    },
    price() {
      return this.order.count * (this.item.price + this.addition_price + this.addition_Uprice)
    },
    price_old() {
      return this.price + ((this.item.price_old - this.item.price) * this.order.count)
    },
    upsale() {
      return this.item && this.item.advanced && this.item.advanced.upsale ? this.item.advanced.upsale : []
    },
    addition_Uprice() {
      return this.order.additionUSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
    },
    addition_price() {
      return this.order.additionSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
    },
    basked() {
      return this.$store.state.busked
    },
    isHotItem() {
      return this.item && this.item.advanced && this.item.advanced.hot
    },
    isStock() {
      return this.item && this.item.stock
    },
    isCatalogMod() {
      return this.$store.getters.getSettings?.catalog_mod
    },
    readMoreOpen() {
      return this.$store.getters.getReadMoreOpen || false
    },

    shortItemDescription() {
      const fullDescription = this.$dynamic(this.item, this.item.description, 'description');
      let resultHTML = "";
      let textLength = 0;
      const maxTextLength = 700;

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = fullDescription;

      let hasSplitTag = false;
      for (let child of tempDiv.children) {
        if (child.tagName.toLowerCase() === "split") {
          hasSplitTag = true;
          break;
        }
      }

      if (hasSplitTag) {
        for (let child of tempDiv.children) {
          if (child.tagName.toLowerCase() === "split") break;
          resultHTML += child.outerHTML;
        }
        return { html: resultHTML, isReadMore: true };
      }

      let firstChild = true;

      for (let child of tempDiv.children) {
        if (firstChild) {
          resultHTML += child.outerHTML;
          textLength += child.innerText?.length || 0;
          firstChild = false;
          if (textLength > maxTextLength) {
            return { html: resultHTML, isReadMore: true };
          }
          continue;
        }

        if (!child.innerText) {
          resultHTML += child.outerHTML;
          continue;
        }

        if (textLength + child.innerText.length <= maxTextLength) {
          resultHTML += child.outerHTML;
          textLength += child.innerText.length;
        } else {
          resultHTML += child.outerHTML;
          return { html: resultHTML, isReadMore: true };
        }
      }

      return { html: resultHTML, isReadMore: false };
    },
    shortItemCharacteristics() {
      const fullCharacteristics = this.$dynamic(this.item, this.item.advanced.text1, 'text1');
      let resultHTML = "";
      let textLength = 0;
      const maxTextLength = 700;

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = fullCharacteristics;

      let hasSplitTag = false;
      for (let child of tempDiv.children) {
        if (child.tagName.toLowerCase() === "split") {
          hasSplitTag = true;
          break;
        }
      }

      if (hasSplitTag) {
        for (let child of tempDiv.children) {
          if (child.tagName.toLowerCase() === "split") break;
          resultHTML += child.outerHTML;
        }
        return { html: resultHTML, isReadMore: true };
      }

      let firstChild = true;

      for (let child of tempDiv.children) {
        if (firstChild) {
          resultHTML += child.outerHTML;
          textLength += child.innerText?.length || 0;
          firstChild = false;

          if (textLength > maxTextLength) {
            return { html: resultHTML, isReadMore: true };
          }
          continue;
        }

        if (!child.innerText) {
          resultHTML += child.outerHTML;
          continue;
        }

        if (textLength + child.innerText.length <= maxTextLength) {
          resultHTML += child.outerHTML;
          textLength += child.innerText.length;
        } else {
          resultHTML += child.outerHTML;
          return { html: resultHTML, isReadMore: true };
        }
      }

      return { html: resultHTML, isReadMore: false };
    },
  },

  watch: {
    async id() {
      await this.getItem();
      this.order = {
        count: 1,
        additionSelect: [],
        additionUSelect: []
      };
      // window.$(`.category-panel-holder a.active`).removeClass('active');
      // window.$(`a[href="/catalog/${this.category}"]`).addClass('active');
      window.scrollTo(0, 0);
      window.$('.product-images a').fancybox();
      // window.$('.frame-modal-wrapper-bg').on('click', function () {
      //   window.$(this).removeClass('is-visible');
      //   window.$('body').removeClass('no-scroll');

      //   window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
      //   window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      //   return false;
      // });
    }
  }
}
</script>

<style scoped>
.label-pic {
  display: inline-block;
  height: 11px;
  margin-right: 3px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 11px;
  margin-top: 0;
}

.videoBox {
  text-align: center;
  position: relative;
  padding-left: 75%;
}

.videoBox video {
  max-width: 100%;
  max-height: 100%;
  z-index: 11;
  width: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
}

.panel-body .text {
  overflow: hidden;
  /*white-space: nowrap;*/
  /*text-overflow: ellipsis;*/
}

.not-in-stock {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>