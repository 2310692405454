<template>
  <div class="default-wrapper wrapper">
    <div class="not-found">
      {{ appData }}
      loading...
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pages',
  data() {
    return {
      appData: undefined,
      start: undefined,
      code: undefined
    }
  },
  methods: {
    getCategory(id) {
      return this.$store.getters.getCategories.find(i => i.id === id);
    },
    // getItem(id) {
    //   return fetch(`/public/item/${id}`).then(response => response.json());
    // },
    async getItem(id) {
      try {
        const response = await fetch(`/public/item/${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data; // Возвращаем данные для дальнейшего использования
      } catch (error) {
        console.error('Error fetching item:', error);
        throw error;
      }
    },

    async openItem() {
      let fbc = undefined;
      // let fbp = undefined;

      if (this.start.includes('_fbclid')) {
        const idx = this.start.indexOf('_fbclid');
        fbc = this.start.slice(idx + '_fbclid'.length);
        this.start = this.start.slice(0, idx);
        localStorage.setItem('_fbc', fbc);
      }

      // if (this.start.includes('_fb')) {
      //   const idx = this.start.indexOf('_fb');
      //   fbp = this.start.slice(idx + 1);
      //   this.start = this.start.slice(0, idx);
      //   localStorage.setItem('_fbp', fbp.replaceAll(".", "_"));
      // }

      const dataSplit = this.start.split('_');
      const [id, code] = dataSplit;

      if (code) {
        this.code = code;
      }

      const hex = id.substring(1);
      const itemID = parseInt(hex, 16);

      try {
        const data = await this.getItem(itemID);
        const c = this.getCategory(data.category);
        let cURL = 'home';

        if (c) {
          cURL = c.info.url;
        }

        if (!this.code) {
          this.code = 't' + itemID;
        }

        this.$Code(this.code);
        this.$router.push({ name: 'ItemOrServiceItem', params: { category: cURL, id: itemID } });
      } catch (error) {
        console.error('Error fetching item:', error);
      }
    },

    openCategory() {
      let hex = this.start.substring(1)
      let category_id = parseInt(hex, 16)
      let c = this.getCategory(category_id)
      let cURL = 'home'
      if (c) {
        cURL = c.info.url
      }
      if (!this.code) {
        this.code = 'c' + category_id
      }
      this.$Code(this.code)
      this.$router.push({ name: 'Category', params: { category: cURL } })
    },
    openPage() {
      this.$router.push(this.start)
    }
  },
  async mounted() {
    if (window.opened) {
      window.Telegram.WebApp.close();
      return
    }
    window.opened = true;
    this.appData = window.location.href;

    if (window.Telegram?.WebApp.initDataUnsafe) {
      this.start = window.Telegram.WebApp.initDataUnsafe.start_param;
    }
    if (this.start) {
      if (this.start[0] === 'k') {
        this.code = this.start.substring(1, 9);
        this.start = this.start.substring(9);
      }
      if (this.start[0] === 't') await this.openItem();
      else if (this.start[0] === 'c') this.openCategory();
      else this.openPage();
    } else {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
