<template>
  <router-view></router-view>
</template>

<script>


export default {
  name: 'App',
  data() {},
  mounted() {
    this.getUtmParams()
  },
  methods: {
    async getUtmParams() {
      // console.log(window.location)
      const searchParams = new URLSearchParams(decodeURI(window.location.search.replaceAll("&amp%3B", "&")));
      // if (localStorage.getItem("utmParams")) {
      //   return
      // }

      // Определяем UTM-метки, которые нас интересуют
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
      const utmData   = {};

      // Проверяем, есть ли UTM-метки в параметрах маршрута
      utmParams.forEach(param => {
        if (searchParams.has(param)) {
          utmData[param] = searchParams.get(param);
        }
      });

      // Если хотя бы одна метка есть, сохраняем их в localStorage
      if (Object.keys(utmData).length > 0) {
        localStorage.setItem('utmParams', JSON.stringify(utmData));
      }
    },
  }
}
</script>