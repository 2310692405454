<template>
  <div class="title-heading">
    <h1>{{ $static('cabinet.title2', 'Історія замовлень') }}</h1>
  </div>
  <div class="personal-order-list-table">
    <div class="personal-order-list-top">
      <div class="name name-date_status">{{ $static('cabinet.tn1', 'Дата/статус:') }}</div>
      <div class="name name-price">{{ $static('cabinet.tn2', 'Сума замовлення:') }}</div>
      <div class="name name-product">{{ $static('cabinet.tn3', 'Товар:') }}</div>
    </div>
    <!--personal area order list item-->
    <HistoryItemRow v-for="(order, index) in list" :key="index" :order="order" />
    <!--end personal area order list item-->
    <div v-if="!list || list.length === 0">
      {{ $static('cabinet.no-order', 'Немає замовлень') }}
    </div>
  </div>

  <div v-if="isPaginationShown" style="display: flex; justify-content: center;">
    <!-- Docs for pagination https://github.com/peshanghiwa/vue-awesome-paginate?tab=readme-ov-file#installation -->
    <vue-awesome-paginate :total-items="totalItems" :items-per-page="perPage" :max-pages-shown="3" v-model="currentPage"
      :hide-prev-next-when-ends="true" @click="handlePaginationClick" />
  </div>
</template>

<script>
import HistoryItemRow from '@/components/HistoryItemRow';

export default {
  name: 'CabinetHistory',
  components: { HistoryItemRow },
  data() {
    return {
      list: [],
      totalItems: undefined,
      perPage: 10,
      currentPage: 1,
    }
  },

  mounted() {
    this.getHistory()
  },

  computed: {
    payment() {
      return this.$store.getters.getPayment
    },
    delivery() {
      return this.$store.getters.getDelivery
    },
    isPaginationShown() {
      if (this.totalItems && this.perPage && (this.totalItems / this.perPage > 1)) {
        return true;
      }
      return false;
    }
  },

  methods: {
    async getHistory() {
      try {
        let ok = false;
        let userInfo = localStorage.getItem('user_info');
        let headers = {};
        let initCache = localStorage.getItem('initData');

        if (initCache && initCache.length) {
          headers.initData = initCache;
          ok = true;
        }

        let body = {};
        if (userInfo) {
          body.user = JSON.parse(userInfo);
          ok = true;
        }

        if (!ok) {
          this.$router.push({ name: 'AuthRequire' });
          return;
        }

        body.filter = { page: this.currentPage }

        let response = await fetch('/public/history', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: headers
        });

        let json = await response.json();

        if (json && json.result) {
          this.list = json.result;
          this.currentPage = json.page;
          this.totalItems = json.total;
        }

        if (json?.error) {
          window.localStorage.removeItem('user_info');
        }
      } catch (error) {
        console.error("Ошибка при получении истории:", error);
      }
    },

    async handlePaginationClick(page) {
      this.currentPage = page;
      await this.getHistory();
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}

.active-page:hover {
  background-color: #2988c8;
}
</style>