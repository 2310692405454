<template>
   <div>
      <!-- Выбранный компонент отображается через динамический компонент -->
      <component :is="resolvedComponent" v-if="resolvedComponent" v-bind="componentProps" />
      <!-- Состояние загрузки -->
      <div v-else-if="loading">{{ $static('item-or-serv_item.loading', 'Йде завантаження данних...') }}</div>
      <!-- Сообщение об ошибке или отсутствии данных -->
      <div v-else>{{ $static('item-or-serv_item.error-message', 'Товар не знайдено або сталася помилка.') }}</div>
   </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Item from '@/views/Item.vue';
import ServiceItem from '@/views/ServiceItem.vue';

export default {
   name: 'ItemOrServiceItem',
   data() {
      return {
         loading: true,
         error: null,
         itemData: {},
      };
   },
   computed: {
      ...mapGetters({
         settings: 'getSettings', // Получаем значение isCatalogMod из Vuex
      }),
      // Выбор компонента на основе состояния и данных
      resolvedComponent() {
         if (!this.itemData) return null;
         if (this.settings.catalog_mod || this.itemData.advanced?.isService) {
            return ServiceItem;
         }
         return Item;
      },
      // Пропсы, которые будут переданы в выбранный компонент
      componentProps() {
         return {
            ...this.$route.params,
            item: this.itemData,
         };
      },
   },

   watch: {
      '$route.params.id': {
         async handler(newId, oldId) {
            if (newId !== oldId) {
               await this.fetchItem(newId);
            }
         },
         immediate: true, // Опционально: выполнить при первом вызове, чтобы учесть первый рендер
      }
   },

   methods: {
      async fetchItem(id) {
         try {
            const response = await fetch(`/public/item/${id}`);
            const data = await response.json();

            if (data.error === 'not found' || !data.result) {
               this.$router.replace({ name: 'NotFound' });
               return;
            }

            this.itemData = { ...data.result };

            // Принудительное обновление компонента
            this.$forceUpdate();
         } catch (err) {
            this.error = err.message;
            console.error('Error fetching item:', err);
         } finally {
            this.loading = false;
         }
      },
   },
   async mounted() {
      const { id } = this.$route.params;
      if (id) {
         await this.fetchItem(id);
      } else {
         this.error = 'Invalid item ID';
         this.loading = false;
      }
   },
};
</script>