<template>
  <header :class="{ 'if-logined': this.isUserLoggedIn }">
    <div class="header-holder">
      <div class="menu-button">
        <a href="javascript:void(0);" class="show-menu-btn" @click.prevent="handleShowMenuBtnClick">
          <span></span>
          <span></span>
          <span></span>
        </a>
        <SearchItem />
      </div>
      <div class="header-info" v-if="s_info" v-html="s_info"></div>
      <div class="logo-holder">
        <router-link :to="{ name: 'home' }">
          <img :src="logo">
        </router-link>
      </div>

      <!--      <div class="header-info right-header-info" v-if="phones">-->
      <!--        <template v-for="(value,key) in phones " :key="key">-->
      <!--          <a :href="'tel:'+value.real" v-html="value.text" v-if="value.isNumber"></a>-->
      <!--          <p v-else v-html="value.text">-->
      <!--          </p>-->
      <!--        </template>-->
      <!--      </div>-->
      <div class="header-btns" v-if="isUserLoggedIn">
        <a v-if="settings.consultant" :href="settings.consultant" class="default-btn btn-outline-light chat-btn">
          <img src="/images/icons/telegram.svg" alt="Зв’язок з менеджером">
          <span class="text">{{ $static('menu_header.manager-connection', 'Зв’язок з менеджером') }}</span>

        </a>
        <!--        <div v-if="settings.consultant" class="line"></div>-->
        <router-link :to="{ name: 'reviews' }" class="default-btn btn-outline-light review-btn">
          <img src="/images/icons/reviews.svg" alt="Відгуки">
          <span class="text">{{ $static('menu_header.reviews', 'Відгуки') }}</span>
        </router-link>
        <!--        <div class="line desktop-hidden"></div>-->
        <a href="javascript:void(0)" @click="sendWebLink"
          class="default-btn btn-outline-light review-btn desktop-hidden">
          <img src="/images/icons/web.svg" alt="Web Версія">
          <span class="text">{{ $static('menu_header.version', 'Web Версія') }}</span>
        </a>
      </div>
      <div class="login-btn" v-else-if="BOT_NAME && !isTelegram">
        <div class="login-btn-holder">
          <telegram-login-temp v-if="BOT_NAME" mode="callback" :size="getSize" requestAccess="write"
            :telegram-login="BOT_NAME" @loaded='telegramLoadedCallbackFunc' @callback="yourCallbackFunction" />
        </div>
      </div>
      <div class="header-btns" :class="{ 'if-logined': this.isUserLoggedIn }">

        <!--        <div class="line"></div>-->
        <router-link :to="{ name: 'reviews' }" class="default-btn btn-outline-light review-btn">
          <img src="/images/icons/reviews.svg" alt="Відгуки">
          <span class="text">{{ $static('menu_header.reviews', 'Відгуки') }}</span>
        </router-link>
      </div>
      <!--      <div class="lang-panel">-->
      <!--        <div class="dropdown show" v-if="lang">-->
      <!--          <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">-->
      <!--            <img :src="current.icon" alt="">-->
      <!--            {{ current.short }}-->
      <!--          </a>-->
      <!--          <div class="dropdown-menu" x-placement="bottom-end">-->
      <!--            <a v-for="(l,index) in lang" :key="index" class="dropdown-item active "-->
      <!--               href="javascript:void(0)" @click="setLang(l)"-->
      <!--               :class="{'active': l.text === current.text}">-->
      <!--              <img :src="l.icon" alt="">-->
      <!--              {{ l.short }}-->
      <!--            </a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </header>
</template>

<script>
import SearchItem from '@/components/SearchItem';
import { telegramLoginTemp } from 'vue3-telegram-login';
import { fixBodyPadding } from '@/utils/fixBodyPadding';

export default {
  name: 'MenuHeader',
  components: { SearchItem, 'telegram-login-temp': telegramLoginTemp },
  emits: ['auth'],

  data() {
    return {
      init: false
    }
  },

  computed: {
    isTelegram() {
      if (window.Telegram && window.Telegram?.WebApp && window.Telegram.WebApp.initData && window?.Telegram?.WebApp?.initData.length > 0) { return true }
      else { return false }
    },
    isUserLoggedIn() {
      if (this.isTelegram) {
        return (window?.Telegram?.WebApp?.initData.length > 0) ? true : false;
      } else {
        return this.$store.getters.getUserInfo ? true : false;
      }
    },
    account() {
      return this.$store.getters.getAccount
    },
    bonus() {
      return this.account?.bonus
    },
    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    getSize() {
      return window.innerWidth < 500 ? 'small' : 'medium'
    },
    settings() {
      return this.$store.getters.getSettings
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    phones() {
      return this.settings && this.settings.phone ?
        this.settings.phone.split(',').map(x => x.trim())
          .map(x => { return { real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+') } }) : false
    },
  },

  watch: {
    settings(val) {
      if (val && val.favicon) {
        let link = document.querySelector('link[rel~=\'icon\']');
        if (link) {
          link.href = val.favicon
        }
      }
    }
  },

  methods: {
    async sendWebLink() {
      try {
        await fetch('/public/get_my_link', {
          method: 'POST',
          body: JSON.stringify({
            user_info: window.Telegram.WebApp.initData,
            ref: window.location.href
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        window.Telegram.WebApp.close();
      } catch (error) {
        console.error('Error sending web link:', error);
      }
    },

    telegramLoadedCallbackFunc() {
      this.isLoaded = true
    },

    yourCallbackFunction(user) {
      localStorage.setItem('user_info', JSON.stringify(user));
      if (window?.Telegram?.WebApp?.initData) {
        localStorage.setItem('initData', JSON.stringify(window.Telegram.WebApp.initData));
      }
      this.$store.commit('setUserInfo', user);
      this.$emit('auth', user);
    },

    handleShowMenuBtnClick() {
      fixBodyPadding();

      this.$store.commit('setBuskedOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      // Обнуляем классы видимости, для того чтоб не было оставшихся видимых элементов при переходе между пунктами мобильного меню

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');

      window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
      window.$('.quick-card-sticker-li').removeClass('active');
      window.$('#quick_cart').removeClass('opened');

      window.$('.overlay-menu').toggleClass('active');
      window.$('.open-nav').toggleClass('is-visible');
      window.$('.show-menu-btn').toggleClass('open');

      window.$('body').toggleClass('no-scroll');
    }
  }
}
</script>