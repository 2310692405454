<template>
  <li class="review-chain-section">
    <div class="review-block" :style="{
      filter: reviewNumber < moderatedReviewsNumber ? 'grayscale(100%)' : 'grayscale(0%)',
      opacity: reviewNumber < moderatedReviewsNumber ? 0.5 : 1
    }">
      <div class="review-block__header">
        <div class="review-block__header-part-wrapper left">
          <div class="review-block__avatar">
            <img v-if="review.body.reviewAuthorAvatar" :src="review.body.reviewAuthorAvatar" alt="">
            <span v-else>{{ getReviewAuthorAvatar() }}</span>
          </div>
          <div class="review-block__username-wrapper">
            <h3 class="review-block__username">{{ getReviewAuthorName() }}</h3>
            <p class="review-block__status">{{ $static('reviews.already-buy', 'вже купив(ла)') }}</p>
          </div>
        </div>
        <div class="review-block__header-part-wrapper right">
          <div class="review-block__date">{{ getReviewDate(review) }}</div>
          <div class="review-block__date-raiting-divider"></div>
          <div class="raiting-container in-review-block">
            <svg width="12" height="12" viewBox="0 0 12 12" class="raiting-star unfilled in-review-block"
              v-for="starPosition in 5" :key="starPosition"> fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.24365 2.05694C5.58086 1.47581 6.42007 1.47581 6.75728 2.05694L7.78996 3.83662C7.88515 4.00067 8.04967 4.11261 8.2372 4.14094L9.95203 4.39994C10.6995 4.51284 10.9617 5.45662 10.3796 5.93892L9.13013 6.97408C8.95476 7.11937 8.8723 7.34855 8.9149 7.57227L9.26489 9.41026C9.40199 10.1302 8.64618 10.6896 7.99767 10.3482L6.29165 9.44988C6.10939 9.35391 5.89154 9.35391 5.70928 9.44988L4.00326 10.3482C3.35475 10.6896 2.59894 10.1302 2.73604 9.41026L3.08603 7.57227C3.12863 7.34856 3.04617 7.11937 2.8708 6.97408L1.62135 5.93892C1.03921 5.45662 1.30141 4.51284 2.04891 4.39994L3.76373 4.14094C3.95126 4.11261 4.11578 4.00067 4.21097 3.83662L5.24365 2.05694ZM6.10858 2.43336C6.06041 2.35034 5.94052 2.35034 5.89235 2.43336L4.85967 4.21304C4.65026 4.57393 4.28831 4.82021 3.87574 4.88252L2.16091 5.14153C2.05413 5.15766 2.01667 5.29248 2.09983 5.36138L3.34928 6.39654C3.7351 6.71618 3.91651 7.22039 3.82279 7.71257L3.4728 9.55056C3.45321 9.65341 3.56119 9.73332 3.65383 9.68454L5.35985 8.78625C5.76083 8.57512 6.2401 8.57512 6.64108 8.78625L8.3471 9.68454C8.43974 9.73332 8.54772 9.65341 8.52813 9.55056L8.17814 7.71257C8.08442 7.22039 8.26583 6.71619 8.65165 6.39654L9.9011 5.36138C9.98426 5.29248 9.9468 5.15766 9.84002 5.14153L8.12519 4.88252C7.71262 4.82021 7.35067 4.57393 7.14126 4.21304L6.10858 2.43336Z"
                fill="#FFC828" />
            </svg>
            <div class="raiting-container filled in-review-block" :style="{ width: getReviewRaiting(review) }">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="raiting-star filled in-review-block"
                v-for="starPosition in 5" :key="starPosition" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.43213 1.49808C6.23944 1.16601 5.75989 1.166 5.5672 1.49808L4.21017 3.83673C4.11498 4.00078 3.95046 4.11272 3.76293 4.14105L1.21977 4.52516C0.792629 4.58967 0.642799 5.12898 0.975452 5.40458L2.87 6.97419C3.04537 7.11948 3.12783 7.34867 3.08523 7.57238L2.58964 10.175C2.5113 10.5864 2.9432 10.906 3.31377 10.7109L5.70847 9.44999C5.89074 9.35402 6.10859 9.35402 6.29085 9.44999L8.68556 10.7109C9.05613 10.906 9.48803 10.5864 9.40968 10.175L8.9141 7.57238C8.8715 7.34867 8.95396 7.11948 9.12933 6.97419L11.0239 5.40458C11.3565 5.12898 11.2067 4.58967 10.7796 4.52516L8.2364 4.14105C8.04887 4.11272 7.88434 4.00078 7.78916 3.83674L6.43213 1.49808Z"
                  fill="#FFC828" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="review-block__main-content">
        <p v-if="getReviewText()" class="review-block__text">{{ getReviewText() }}</p>
        <div class="review-block__tag-container" v-if="review.body.tags.length > 0">
          <div class="review-block__tag" v-for="(tagInReview, tagInReviewIndex) in review.body.tags"
            :key="tagInReviewIndex">{{ tagInReview.toLowerCase() }}
          </div>
        </div>
        <div class="review-block__photo-container" v-if="review.body.photo.length > 0">
          <img class="review-block__photo" :src="reviewPhoto" alt="фото в отзыве"
            v-for="(reviewPhoto, reviewPhotoIndex) in review.body.photo " :key="reviewPhotoIndex">
        </div>
        <div class="review-block__answers-counter">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
            :fill="validAnswers ? '#17c784' : '#CCCCCC'">
            <g clip-path="url(#clip0_1735_857)">
              <path
                d="M10.0725 0.808594H1.91581C1.063 0.808594 0.369141 1.50231 0.369141 2.35526V7.34697C0.369141 8.198 1.05997 8.89062 1.91032 8.89364V11.1588L5.16563 8.89364H10.0725C10.9253 8.89364 11.6191 8.19978 11.6191 7.34697V2.35526C11.6191 1.50231 10.9253 0.808594 10.0725 0.808594ZM10.96 7.34697C10.96 7.83627 10.5618 8.23446 10.0725 8.23446H4.95882L2.5695 9.8971V8.23446H1.91581C1.42644 8.23446 1.02832 7.83627 1.02832 7.34697V2.35526C1.02832 1.86582 1.42644 1.46777 1.91581 1.46777H10.0725C10.5618 1.46777 10.96 1.86582 10.96 2.35526V7.34697Z" />
              <path d="M3.38086 3.1377H8.60884V3.79688H3.38086V3.1377Z" />
              <path d="M3.38086 4.54395H8.60884V5.20312H3.38086V4.54395Z" />
              <path d="M3.38086 5.9502H8.60884V6.60938H3.38086V5.9502Z" />
            </g>
            <defs>
              <clipPath id="clip0_1735_857">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>{{ $static('review.unswers', 'Відповідей') }} {{ validAnswers ? review?.body?.answers?.length : 0
            }}</span>
        </div>
      </div>
    </div>
    <div class="review-block__answers-container" v-if="validAnswers">
      <div class="review-block review-block--answer" v-for="(reviewAnswer, reviewAnswerIndex) in review?.body?.answers"
        :key="reviewAnswerIndex">
        <div class="review-block__header">
          <div class="review-block__header-part-wrapper left">
            <div class="review-block__avatar">
              <img v-if="isReviewAnswerAvatar" :src="this.$store.getters.getSettings?.logo" alt="аватар">
              <span v-else>М</span>
            </div>
            <div class="review-block__username-wrapper">
              <h3 class="review-block__username">{{ $static('review.username', 'Менеджер') }}</h3>
              <p class="review-block__status">{{ $static('review.user-status', 'отдел контроля качества') }} </p>
            </div>
          </div>
          <div class="review-block__header-part-wrapper right">
            <div class="review-block__date">{{ getReviewAnswerDate(reviewAnswer) }}</div>
          </div>
        </div>

        <div class="review-block__main-content">
          <p class="review-block__text">{{ reviewAnswer?.text ?? "" }}</p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'ReviewItem',
  props: {
    review: {
      type: Object,
      required: true
    },
    reviewNumber: {
      type: Number,
      required: true
    },
    moderatedReviewsNumber: {
      type: Number,
      required: true
    }
  },

  computed: {
    validAnswers() {
      if (Array.isArray(this.review.body.answers) && this.review.body.answers[0] && this.review.body.answers[0]?.text) {
        return true;
      }
      return false;
    },
    isReviewAnswerAvatar() {
      if ((this.$store.getters.getSettings?.logo !== undefined || this.$store.getters.getSettings?.logo !== null) && typeof this.$store.getters.getSettings?.logo == "string" && this.$store.getters.getSettings.logo.length > 0) {
        return true
      }
      return false;
    }
  },

  methods: {
    getReviewAuthorAvatar() {
      if (typeof this.review?.body?.name === "string") {
        return this.review?.body?.name.charAt(0).toUpperCase();
      } else {
        return "А";
      }
      // тут не хватает функционала для выбора или картинки аватара иди если ее нет - первого символа имени как сейчас реализовано
    },
    getReviewAuthorName() {
      // const firstName = this.review?.body?.first_name.charAt(0).toUpperCase() + this.review?.body?.first_name.slice(1);
      // const lastName = this.review?.body?.last_name.charAt(0).toUpperCase() + this.review?.body?.last_name.slice(1);

      // return `${firstName} ${lastName}`;
      if (typeof this.review?.body?.name === "string") {
        return this.review?.body?.name.charAt(0).toUpperCase() + this.review?.body?.name.slice(1);
      } else {
        return this.$static('busked.anon-customer', 'Анонiмний замовник');
      }
    },
    // getReviewDate(rev) {
    //   if (rev?.body?.reviewTime) {
    //     const months = [
    //       'січень', 'лютий', 'березень', 'квітень', 'травень', 'червень',
    //       'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'
    //     ];

    //     const dateFromBackend = new Date(rev.body.reviewTime * 1000);
    //     const day = dateFromBackend.getDate();
    //     const month = months[dateFromBackend.getMonth()];
    //     const year = dateFromBackend.getFullYear();

    //     if (year.toString().length !== 4) {
    //       return '-';
    //     }
    //     return `${day} ${month} ${year}`;
    //   }

    //   return '-';
    // },
    getReviewDate(rev) {
      if (rev?.body?.reviewTime) {
        // Локаль пользователя
        let userLang = 'uk-UA';

        if (localStorage.getItem('$lang')) {
          switch (localStorage.getItem('$lang')) {
            case 'RU':
              userLang = 'ru-RU';
              break;
            case 'EN':
              userLang = 'en-US';
              break;
            default:
              // userLang = navigator.language || 'uk-UA';
              userLang = 'uk-UA'; // По умолчанию украинская локаль

          }
        }

        const dateFromBackend = new Date(rev.body.reviewTime * 1000);

        // Опции форматирования
        const options = {
          day: 'numeric',    // День
          month: 'long',     // Полное название месяца
          year: 'numeric',   // Год
        };

        // Форматирование даты
        return new Intl.DateTimeFormat(userLang, options).format(dateFromBackend);
      }

      return '-';
    },

    getReviewRaiting(rev) {
      return rev?.body?.reviewRaiting ? (rev.body.reviewRaiting * 20 + "%") : 0;
    },
    getReviewText() {
      return this.review?.body?.comment || "";
    },
    getReviewAnswerDate(revAnswer) {
      if (revAnswer.time) {
        const months = [
          'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
          'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];

        const dateFromBackend = new Date(revAnswer.time * 1000);
        const day = dateFromBackend.getDate();
        const month = months[dateFromBackend.getMonth()];
        const year = dateFromBackend.getFullYear();

        if (year.toString().length !== 4) {
          return '-';
        }
        return `${day} ${month} ${year}`;
      }

      return '-';
    },
  }
}
</script>

<style lang="scss" scoped>
.raiting-container {
  position: relative;
  display: flex;
  column-gap: 7px;
  margin-bottom: 8px;
  overflow: hidden;

  &.in-review-block {
    column-gap: 2px;
    margin-bottom: 0;
  }

  &.filled {
    position: absolute;
    top: 0;
    left: 0;
    width: 89%;
  }

  @media (max-width: 767px) {
    margin-bottom: -11px;
  }
}

.raiting-star {
  flex-grow: 0;
  flex-shrink: 0;
  width: 42px;
  height: 38.25px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.in-review-block {
    width: 18px;
    height: 18px;

    @media (max-width: 767px) {
      width: 13px;
      height: 11px;
    }
  }

  @media (max-width: 767px) {
    width: 22px;
    height: 18px;
  }
}

.review-chain-section {
  margin-bottom: 9px;
}

.review-block {
  position: relative;
  padding: 10px 0 11px 0;
  border-radius: 11px;
  border: 1px solid rgba(0, 0, 0, 0.10);


  &.review-block--answer {
    margin-top: 14px;
    margin-left: 77px;

    @media (max-width: 767px) {
      margin-top: 7px;
      margin-left: 37px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 27px;
      height: 81px;
      top: -15px;
      left: -27px;
      border-left: 1px solid #D9D9D9;
      border-bottom: 1px solid #D9D9D9;

      @media (max-width: 767px) {
        width: 17px;
        height: 71px;
        top: -9px;
        left: -17px;
      }
    }

    & .review-block__text {
      margin-right: 27px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 10px;
  }
}

.review-block__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;

  @media (max-width: 767px) {
    margin-bottom: 8px;
    padding-bottom: 10px;
  }
}

.review-block__header-part-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 767px) {
    gap: 5px;
  }

  &.left {
    margin-left: 12px;
  }

  &.right {
    gap: 3px;
    margin-right: 27px;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin-right: 12px;
    }
  }
}

.review-block__username-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.review-block__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E9E9E9;
  overflow: hidden;

  & span {
    line-height: 1;
  }

  & img {
    width: 100%;
    /* object-fit: cover; */
  }

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.review-block__username {
  color: #171717;
  font-size: 16px;
  margin-bottom: 3px;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.review-block__status {
  margin-top: -2px;
  margin-bottom: 0;
  color: #747474;
  font-size: 12px;
  line-height: normal;

  &::after {
    position: relative;
    top: 2px;
    content: url("/public/images/icons/already-buy-status-in-review.svg");
    margin-left: 3px;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.review-block__date-raiting-divider {
  width: 1px;
  height: 13px;
  background-color: #D9D9D9;
  margin: 0 3px;

  @media (max-width: 767px) {
    display: none;
  }
}

.review-block__date {
  color: #747474;
  font-size: 12px;
  position: relative;
  top: 1px;
  line-height: normal;
}

.review-block__main-content {
  padding: 0 27px;

  @media (max-width: 767px) {
    padding: 0 12px 20px;
  }
}

.review-block__text {
  margin-right: 108px;
  margin-bottom: 8px;
  color: #353535;
  font-size: 16px;
  line-height: normal;
  min-height: 20px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 6px;
    font-size: 13px;
  }
}

.review-block__tag-container {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 108px;
  margin-bottom: 15px;


  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
}

.review-block__tag {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px 7px 5px 17px;
  border: 1px solid #C5C5C5;
  border-radius: 6px;
  color: #747474;
  font-size: 14px;
  line-height: normal;

  @media (max-width: 767px) {
    padding: 2px 10px 3px 16px;
    font-size: 11px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translate(0, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #17c784;
  }
}

.review-block__photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin-right: 108px;

  @media (max-width: 767px) {
    gap: 3px;
  }
}

.review-block__photo {
  width: 76px;
  height: 60px;
  border-radius: 3px;
  object-fit: cover;

  @media (max-width: 767px) {
    width: 39px;
    height: 31px;
  }
}

.review-block__answers-counter {
  position: absolute;
  bottom: 11px;
  right: 27px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  color: #353535;
  font-size: 16px;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 2px;

  @media (max-width: 767px) {
    gap: 8px;
    right: 12px;
    font-size: 12px;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
}
</style>