<template :key="category">
  <div class="catalog-wrapper wrapper">

    <!--    <div class="custom-header category-header bg-cover" v-if="categoryImage"-->
    <!--         :style="{'background-image': $filters.getItemImage(categoryImage)}">-->
    <!--      &lt;!&ndash;      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>&ndash;&gt;-->
    <!--    </div>-->
    <div class="custom-header category-header" v-if="categoryImage">
      <img :src="categoryImage" alt="">
    </div>
    <div class="category-header custom-header min-header" v-else>
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="cat-title">
      <h1>{{ $dynamic(categoryObject, categoryName, 'name') }}</h1>
    </div>
    <div class="sorting-panel" v-if="tags && tags.length || (groups && groups.length)">
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{ $static('options.all', 'Усі') }}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in short_tags" :key="index">
          <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label v-if="op" :for="op.id">
            <span class="pick" v-if="op.image" :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">
              <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
              <span class="custom-option-price">
                <span class="price" v-if="op.price">
                  {{ $filters.money(op.price) }} {{ $store.getters.getSettings.symbol }}
                </span>
                <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }} {{
                  $store.getters.getSettings.symbol
                }}</span>
              </span>
            </span>
          </label>
        </div>
        <div class="filter-trigger-holder" v-if="tags && tags.length > settings?.labelQuantity || groups?.length">
          <a href="javascript:void(0);" @click="toggleFilter" class="filter_trigger">
            <span class="text">{{ $static('catalog.open-filter', 'Відкрити фільтр') }}</span>
            <img src="/images/icons/filter.svg" alt="">
          </a>
        </div>
      </div>
    </div>

    <div class="catalog-grid-holder">
      <ul class="catalog-grid" :class="{ 'double-items': $store.getters.getSettings.catalog_duo }">
        <CatalogItemPreview v-for="(item, index) in items" :key="index" :item="item" :categoryObject="categoryObject"
          @leaveLead="handleLeaveLeadBtnClick" />
        <div ref="loadMore"></div>
      </ul>
    </div>
  </div>

  <div class="fixed-filter-trigger">
    <a href="javascript:void(0);" @click="toggleFilter" class="filter_trigger">
      <img src="/images/icons/filter.svg" alt="">
    </a>
  </div>

  <div class="filter_sidebar" :class="{ 'opened': filterState }">
    <div class="filter_sidebar_wrapper">
      <div class="panel-top">
        <div class="panel-top-name">{{ $static('catalog.filter', 'Фільтри') }}</div>
        <a href="javascript:void(0);" @click="toggleFilter" class="close_filter">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>

      <div class="panel-middle">
        <div class="filter-list">
          <div class="filter-holder">

            <div class="filter-panel">
              <div class="sorting-panel" v-if="(tags && tags.length)">
                <div class="sort-list">
                  <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
                    <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                    <label v-if="op" :for="op.id">
                      <span class="pick" v-if="op.image"
                        :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>
                      <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                      <span class="text">
                        <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
                        <span class="custom-option-price">
                          <span class="price" v-if="op.price"> {{ $filters.money(op.price) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                          <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="sorting-panel" v-for="(group, gIndex) in groups" :key="gIndex">
                <div class="custom-label">
                  {{ $dynamic(group, group.name, 'name') }}
                </div>
                <div class="sort-list">
                  <div class="sorting-checkbox" v-for="(op, index) in group.list" :key="index">
                    <input v-if="op" :id="gIndex + '_' + op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                    <label v-if="op" :for="gIndex + '_' + op.id">
                      <span class="pick" v-if="op.image"
                        :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>

                      <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                      <span class="text">
                        <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
                        <span class="custom-option-price">
                          <span class="price" v-if="op.price"> {{ $filters.money(op.price) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                          <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="panel-bottom">
        <button href="javascript:void(0)" @click="toggleFilter" class="default-btn reload_filter">
          <span class="text">{{ $static('catalog.reset-filter', 'Скинути') }}</span>
          <img src="/images/icons/refresh.svg">
        </button>
        <button href="javascript:void(0)" @click="toggleFilter" class="default-btn btn-accent apply-filter">
          <span class="text">{{ $static('catalog.use-filter', 'Застосувати') }}</span>
        </button>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <div class="frame-modal-wrapper-bg filter-modal-wrapper-bg" @click="toggleFilter"
      :class="{ 'is-visible': filterState }">
    </div>
  </Teleport>

  <ServiceRequestModal :settings="settings" :item="selectedItem" v-if="selectedItem" />

  <div class="preloader-page proccess-load" v-if="displayLoad">
    <img src="/images/icons/circle-notch-solid.svg" class="preloader-spinner">
  </div>

  <div class="load-more-reviews" v-if="load">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M15.1634 8.73898C14.6358 8.66188 14.1673 9.00919 14.0916 9.50434C13.9016 10.748 13.304 11.8827 12.3632 12.7862C9.96888 15.0857 6.07593 15.0839 3.68255 12.7862C1.28965 10.4876 1.28965 6.74739 3.68255 4.44922C4.58269 3.58518 5.71903 3.02182 6.9908 2.81673C7.53772 2.71705 8.12573 2.69982 8.73583 2.76621C9.57354 2.85882 10.3533 3.12576 11.0648 3.51769L9.83525 3.71933C9.32157 3.80396 8.97562 4.27253 9.06421 4.76633C9.1426 5.20833 9.542 5.52053 9.99391 5.52053C10.0465 5.52053 10.1 5.51654 10.1544 5.50765L13.4506 4.96642C13.6968 4.92569 13.9173 4.79281 14.0621 4.59617C14.2069 4.39997 14.2642 4.15634 14.2217 3.91897L13.6572 0.753672C13.5705 0.260332 13.089 -0.0701023 12.567 0.0127105C12.0533 0.0973373 11.7074 0.566368 11.796 1.06016L11.9445 1.89328C11.0304 1.40075 10.0221 1.08211 8.95069 0.963652C8.1483 0.875941 7.3699 0.90034 6.65742 1.03141C5.01713 1.29536 3.52672 2.03369 2.34703 3.16658C-0.782344 6.17206 -0.782344 11.0625 2.34703 14.0687C3.91167 15.5719 5.96751 16.3231 8.02288 16.3231C10.0787 16.3231 12.1335 15.572 13.6987 14.0687C14.9282 12.888 15.7103 11.4008 15.9602 9.76829C16.0359 9.27223 15.6789 8.81154 15.1634 8.73898Z"
        fill="#353535" />
    </svg>
  </div>

</template>

<script>
import CatalogItemPreview from '@/views/CatalogItemPreview';
import ServiceRequestModal from '@/components/ServiceRequestModal';

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export default {
  name: 'Catalog',
  components: { CatalogItemPreview, ServiceRequestModal },
  props: ['category'],
  data() {
    return {
      optionSelect: [],
      items: [],
      selectedItem: undefined,
      filterState: false,
      load: false,
      lastLoadT: 0,
      page: 1,
      perPage: 12,
      total: 0,
      more: true,
      checkLoader: undefined,
      loadBefore: 0,
      loadTimeout: undefined,
      displayLoad: false
    }
  },

  async mounted() {
    this.checkLoader = this.$refs.loadMore;

    await this.getItems();
    this.setSeo(this.categoryObject);

    this.$ViewCategory(this.categoryObject)

    window.document.addEventListener('scroll', this.onscroll);
  },

  beforeUnmount() {
    window.document.removeEventListener('scroll', this.onscroll);
  },

  updated() {
    if (this.items.length === this.total) {
      this.more = false;
    }
    else {
      this.more = true;
    }

    if (this.more) {
      this.loadBefore = this.checkLoader.offsetTop;
    } else {
      let body = document.body, html = document.documentElement;
      this.loadBefore = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 1000;
    }
  },

  methods: {
    toggleFilter() {
      this.filterState = !this.filterState
    },

    async onscroll(e) {
      if (document.documentElement.scrollTop + window.innerHeight > this.loadBefore && this.more) {
        this.page += 1;
        await this.getScrollItems();
      }
    },

    getColor(item) {
      return `background-color: ${item.color};`
    },

    loadLater(clear) {
      if (this.loadTimeout) {
        clearTimeout(this.loadTimeout)
      }
      this.loadTimeout = setTimeout(async () => {
        this.load = false
        this.displayLoad = false;
        await this.getItems(clear)
      }, 100)
    },

    async getItems(clear) {
      if (this.settings.symbol === undefined) {
        this.loadLater(clear);
        return;
      }

      // if (this.load) {
      //   return;
      // }
      this.load = true;
      this.displayLoad = true;

      if (clear) {
        this.page = 1;
        this.more = true;
      }

      const filter = { page: this.page, perPage: this.perPage };

      if (this.searchBy.length) {
        filter.category = this.searchBy;
      } else if (this.settings.menu_type === 'advanced') {
        this.loadLater(clear);
        return;
      } else if (this.categoryName) {
        filter.category = this.categoryObject.id;
      } else {
        filter.url = this.category;
      }

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.$store.getters.getTags.find(o => o.id === id)).map(x => x ? x.id : x);
      } else if (filter.tags) {
        delete filter.tags;
      }

      const loadT = new Date().getTime();

      try {
        const response = await fetch('/public/item/filter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter: filter, sortBy: 'sort' })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.load = false;
        this.displayLoad = false;

        if (loadT < this.lastLoadT) {
          return;
        }
        this.lastLoadT = loadT;

        if (clear) {
          this.items = data.result;
        } else {
          for (let item of data.result) {
            if (this.items.findIndex(x => x.id === item.id) === -1) {
              this.items.push(item);
            }
          }
        }

        this.page = data.page;
        this.total = data.total;

        if (this.items.length === this.total) {
          this.more = false;
        }
      } catch (error) {
        console.error('Error fetching items:', error);
        this.load = false;
        this.displayLoad = false;
      }
    },

    async getScrollItems(clear) {
      if (this.settings.symbol === undefined) {
        this.loadLater(clear);
        return;
      }

      if (this.load) {
        return;
      }
      this.load = true;
      this.displayLoad = false;

      if (clear) {
        this.page = 1;
        this.more = true;
      }

      const filter = { page: this.page, perPage: this.perPage };

      if (this.searchBy.length) {
        filter.category = this.searchBy;
      } else if (this.settings.menu_type === 'advanced') {
        this.loadLater(clear);
        return;
      } else if (this.categoryName) {
        filter.category = this.categoryObject.id;
      } else {
        filter.url = this.category;
      }

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.$store.getters.getTags.find(o => o.id === id)).map(x => x ? x.id : x);
      } else if (filter.tags) {
        delete filter.tags;
      }

      const loadT = new Date().getTime();

      try {
        const response = await fetch('/public/item/filter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter: filter, sortBy: 'sort' })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.load = false;
        this.displayLoad = false;

        if (loadT < this.lastLoadT) {
          return;
        }
        this.lastLoadT = loadT;

        if (clear) {
          this.items = data.result;
        } else {
          for (let item of data.result) {
            if (this.items.findIndex(x => x.id === item.id) === -1) {
              this.items.push(item);
            }
          }
        }

        this.page = data.page;
        this.total = data.total;

        if (this.items.length === this.total) {
          this.more = false;
        }
      } catch (error) {
        console.error('Error fetching items:', error);
        this.load = false;
        this.displayLoad = false;
      }
    },

    handleLeaveLeadBtnClick(item) {
      this.selectedItem = item;
    },

    setSeo(val) {
      if (val && val.info) {
        let title = val.info.title ? val.info.title : this.$dynamic(this.categoryObject, this.categoryName, 'name');

        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = val.info.description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');
        let image = val.image ? `${window.location.protocol}//${window.location.host}${val.image}` : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },

  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    categoryGroups() {
      return this.categoryObject?.info?.groups
    },
    groups() {
      return this.categoryGroups ?
        this.categoryGroups.map(group => {
          let list = group.list.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id)
          list = list.sort((a, b) => {
            if (isNumeric(a.name) && isNumeric(b.name)) {
              return parseFloat(a.name) - parseFloat(b.name)
            }

            return b.name.localeCompare(a.name)
          })
          return Object.assign({}, group, { list: list })
        }) : this.categoryGroups
    },
    searchBy() {
      return this.$store.getters.getSearchBy
    },
    categoryTags() {
      return this.categoryObject?.info?.tags
    },
    tags() {
      return this.categoryTags ?
        this.categoryTags.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id).sort((a, b) => a.order - b.order)
        : []
    },
    short_tags() {
      return this.tags.slice(0, this.settings?.labelQuantity)
    },
    storeTags() {
      return this.$store.getters.getTags
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    categoryObject() {
      return this.$store.getters.getCategories.find(i => i.info.url === this.category)
    },
    categoryName() {
      return this.categoryObject ? this.categoryObject.name : ''
    },
    categoryImage() {
      return this.categoryObject && this.categoryObject.image ? this.categoryObject.image : ''
    }
  },

  watch: {
    async category(newVal) {
      this.optionSelect = [];
      if (this.searchBy?.length) {
        return
      }
      this.$ViewCategory(this.categoryObject)

      await this.getItems(true)
    },
    async searchBy() {
      this.$ViewCategory(this.categoryObject)
      await this.getItems(true)
    },
    async optionSelect(val) {
      await this.getItems(true)
    },
    async categoryObject(val) {
      await this.setSeo(val)
    }
  }
}
</script>