<template>
  <div v-html="html" class="default-wrapper wrapper"></div>
</template>

<script>
export default {
  name: 'Page',
  props: ['pagename'],

  data() { return { html: '', lang: '', page: undefined } },

  computed: {
    isUpdate() {
      return this.$updateLang()
    },
    path() {
      return this.$route.path;
    }
  },

  watch: {
    async isUpdate() {
      await this.getPage()
    },
    async pagename(page) {
      await this.getPage(page)
    }
  },
  methods: {
    async getPage(page) {
      let query = '';
      let lang = localStorage.getItem('$lang');

      if (this.lang !== lang || page !== this.page) {
        if (lang) {
          query = `?lang=${lang}`;
        }

        try {
          const response = await fetch(`/public/template/${this.pagename}${query}`);

          if (!response.ok) {
            throw new Error('Сетевая ошибка: ' + response.statusText);
          }

          const data = await response.json();
          let name = this.$route.name;
          let settings = data && data.settings ? data.settings : {};

          let title = settings.meta_title || name;
          let description = settings.meta_description || '';
          let image = settings.image ? `${window.location.protocol}//${window.location.host}${settings.image}` : '';

          document.querySelector('title').innerHTML = title;
          document.querySelector('meta[property="og:title"]').setAttribute('content', title);
          document.querySelector('meta[name="description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:image"]').setAttribute('content', image);

          this.html = data.template;

        } catch (error) {
          console.error('Ошибка при получении данных:', error);
        }
      }
      this.page = page;
      this.lang = lang;
    }
  },

  async mounted() {
    await this.getPage(this.pagename);
  }
}
</script>